import {MobileHeader} from '#/components/MobileHeader';
import Page from '#/components/Page';
import ScoutSpinner from '#/components/ScoutSpinner';
import {TaskRunLogVariables} from '#/components/tasks/TaskRunLogVariables';
import {useTaskQuery, useTaskRunQuery} from '#/hooks/query/tasks';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import {RunLogResult, TaskRunStatusEnum} from '#/repositories/assistants-api/requests/fetch-task-run';
import {stopTask} from '#/repositories/assistants-api/requests/stop-task';
import {GearIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, NavLink, useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as StopIcon} from 'src/resources/trash-icon.svg';

type TaskRunPageParams = {
  taskId: string;
  taskRunId: string;
};

const TaskRunPage: FunctionComponent = () => {
  const {t} = useTranslation();

  const {taskId, taskRunId} = useParams<TaskRunPageParams>();
  const [shouldPoll, setShouldPoll] = useState(false);

  const {data: taskRun} = useTaskRunQuery({taskId, runId: taskRunId, shouldPoll});
  const {data: task} = useTaskQuery({taskId: taskRun?.task_id, shouldPoll: false});
  const navigate = useNavigate();

  useEffect(() => {
    if (taskRun) {
      setShouldPoll(taskRun.status === TaskRunStatusEnum.IN_QUEUE || taskRun.status === TaskRunStatusEnum.RUNNING);
    } else {
      setShouldPoll(true);
    }
  }, [taskRun, setShouldPoll]);

  const handleStopClick = useCallback(async () => {
    await stopTask(taskId || '', taskRunId || '');
    navigate(`/tasks/${taskId}/runs`);
  }, [navigate, taskId, taskRunId]);

  if (!taskRun || !task) {
    return null;
  }

  const TaskStatusIcon: FunctionComponent<{status: 'SUCCESS' | 'ERROR'}> = props => {
    return (
      <div
        className={(props.status === 'SUCCESS' ? 'bg-green-600' : 'bg-red-600') + ' w-[24px] h-[24px] rounded-full'}
      />
    );
  };

  const TaskRunLogResultCard: FunctionComponent<{runLog: RunLogResult}> = props => {
    return (
      <div className='flex overflow-hidden grow text-balance rounded-xl border border-stroke-main bg-surface-01 px-4 py-6 shadow mb-2'>
        <div className='flex w-full justify-between'>
          <div className='flex'>
            <div>
              <TaskStatusIcon status={props.runLog.status} />
            </div>
            <div className='mx-2'>
              <p title={props.runLog.step_description}>{props.runLog.step_description}</p>
              {props.runLog.result && <div className='text-xs shrink ml-4'>{props.runLog.result}</div>}
              <div className='text-xs shrink ml-4'>
                <TaskRunLogVariables variables={props.runLog.variables} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TaskRunLogResultExceptionCard: FunctionComponent<{runLog: RunLogResult}> = props => {
    return (
      <div className='flex overflow-hidden grow text-balance rounded-xl border border-stroke-main bg-warning px-4 py-6 shadow mb-2'>
        <div className='flex w-full justify-between'>
          <div className='flex'>
            <div>
              <TaskStatusIcon status={props.runLog.status} />
            </div>
            <div className='mx-2'>
              <p title={props.runLog.exception ?? props.runLog.step_description}>
                {props.runLog.exception ?? props.runLog.step_description}
              </p>
              {props.runLog.exception && <div className='text-xs shrink ml-4'>{props.runLog.exception_detail}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TaskRunLogProgressCard: FunctionComponent<{runLog: RunLogResult}> = props => {
    return (
      <div className='flex overflow-hidden grow text-balance rounded-xl border border-stroke-main bg-surface-02 px-4 py-6 shadow mb-2'>
        <div className='flex w-full justify-between'>
          <div className='flex'>
            <div>
              <ScoutSpinner />
            </div>
            <div className='mx-2'>
              <p title={props.runLog.step_description}>{props.runLog.step_description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Page title={task.description}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>{task.description} </h2>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch px-4 md:px-6 pb-3 md:pb-6 overflow-y-auto'>
        <PageContentHeader title={task.description}>
          <div className='flex gap-4 items-center'>
            <Link draggable={false} to={`/tasks/${task.id}/runs`} className='text-xs text-secondary mr-4 text-nowrap'>
              {t('task-run.actions.navigate-to-runs')}
            </Link>
            <NavLink
              to={`/tasks/${taskId}/edit`}
              className='flex gap-2 h-12 rounded-lg bg-surface-02 p-4 items-center z-10 hover:opacity-70 transition-opacity group'
            >
              <GearIcon className='shrink-0 group-hover:rotate-[30deg] transition stroke-primary' />
              <span className='text-nowrap font-bold'>{t('task-runs.actions.edit-task')}</span>
            </NavLink>
            {shouldPoll && (
              <button
                className='flex items-center bg-red-600 text-inverse rounded-xl font-bold px-4 py-2 gap-2 hover:opacity-70 transition-opacity'
                data-variant='cancel'
                onClick={handleStopClick}
              >
                <StopIcon className='stroke-inverse' />
                {t('task-runs.actions.stop')}
              </button>
            )}
          </div>
        </PageContentHeader>

        <div className='max-w-page-content w-full flex flex-col mx-auto gap-y-3'>
          <div className='mt-4 gap-3 sm:gap-5 w-full'>
            {taskRun?.run_log?.map((runLog, index) =>
              runLog.exception || runLog.status === 'ERROR' ? (
                <TaskRunLogResultExceptionCard runLog={runLog} key={index} />
              ) : runLog.status === 'SUCCESS' ? (
                <TaskRunLogResultCard runLog={runLog} key={index} />
              ) : (
                <div>{shouldPoll && <TaskRunLogProgressCard runLog={runLog} key={index} />}</div>
              ),
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default TaskRunPage;
