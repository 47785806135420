import AssistantsAPI from '#/repositories/assistants-api/api.ts';
import {AxiosResponse} from 'axios';

export type DeleteTaskScheduleRequest = {
  task_id: string;
  schedule_id: string;
};

export const deleteTaskSchedule = async (request: DeleteTaskScheduleRequest): Promise<AxiosResponse<null>> => {
  return AssistantsAPI.delete(`/tasks/${request.task_id}/schedules/${request.schedule_id}`);
};
