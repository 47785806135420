import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {CreateConversationRequest} from '#/repositories/assistants-api/requests/create-conversation.ts';
import {AxiosResponse} from 'axios';

const BASE_ENDPOINT = '/external/';

export interface PrepareExternalLoginResponse {
  nonce: string;
}

export const prepareExternalLogin = (service: string): Promise<AxiosResponse<PrepareExternalLoginResponse>> => {
  return ScoutAPI.post<CreateConversationRequest, AxiosResponse<PrepareExternalLoginResponse>>(
    `${BASE_ENDPOINT}login/${service}/prepare/`,
  );
};

export const externalLogout = (service: string): Promise<AxiosResponse> => {
  return ScoutAPI.post<CreateConversationRequest, AxiosResponse>(`${BASE_ENDPOINT}logout/${service}/`);
};
