import Markdown from '#/components/Markdown.tsx';
import {MobileHeader} from '#/components/MobileHeader.tsx';
import Page from '#/components/Page.tsx';
import useCapabilitySteps from '#/hooks/create-edit-task/use-capability-steps.tsx';
import useCodeString from '#/hooks/create-edit-task/use-code.tsx';
import useFieldValues from '#/hooks/create-edit-task/use-field-values.tsx';
import useOriginalSteps from '#/hooks/create-edit-task/use-original-steps.tsx';
import useSelectedStep from '#/hooks/create-edit-task/use-selected-step.tsx';
import useSetFormDataOnCapabilityChange from '#/hooks/create-edit-task/use-set-form-data-on-capability-change.tsx';
import useStepSelectOptions from '#/hooks/create-edit-task/use-step-select-options.tsx';
import {useCachedTaskQuery, useUpdateTaskCapabilitySteps} from '#/hooks/query/tasks.tsx';
import {Button} from '#/library/button/Button.tsx';
import Label from '#/library/label/Label.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import Select from '#/library/select/Select.tsx';
import {Textarea} from '#/library/textarea/Textarea.tsx';
import {EditedStep} from '#/repositories/assistants-api/requests/update-task-capability-step.ts';
import {ReactComponent as CheckmarkIcon} from '#/resources/checkmark-icon.svg';
import {Pencil1Icon} from '@radix-ui/react-icons';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useParams} from 'react-router-dom';

type EditTaskStepsPageParams = {
  taskId: string;
  capabilityId: string;
};

const EditTaskStepsPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {taskId, capabilityId} = useParams<EditTaskStepsPageParams>();

  const task = useCachedTaskQuery(taskId);
  const capabilitySteps = useCapabilitySteps(capabilityId, task?.capabilities);
  const originalSteps = useOriginalSteps(capabilitySteps);
  const stepSelectOptions = useStepSelectOptions(capabilitySteps);
  const [editedSteps, setEditedSteps] = useState<(EditedStep | null)[]>([]);
  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(0);
  useSetFormDataOnCapabilityChange(capabilitySteps, setSelectedStepIndex, setEditedSteps);
  const fieldValues = useFieldValues(editedSteps, originalSteps, selectedStepIndex);
  const selectedStep = useSelectedStep(capabilitySteps, selectedStepIndex);
  const codeString = useCodeString(selectedStep);

  const handleOnChangeStep = useCallback((value: string) => {
    setSelectedStepIndex(Number(value));
  }, []);

  const handleOnPromptChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newEditedSteps = [...editedSteps];

      newEditedSteps[selectedStepIndex] = {
        code: newEditedSteps[selectedStepIndex]?.code || null,
        prompt: e.target.value,
      };
      setEditedSteps(newEditedSteps);
    },
    [editedSteps, selectedStepIndex],
  );

  const setCode = useCallback(
    (newCode: string) => {
      const newEditedSteps = [...editedSteps];

      newEditedSteps[selectedStepIndex] = {
        code: newCode,
        prompt: newEditedSteps[selectedStepIndex]?.prompt || null,
      };
      setEditedSteps(newEditedSteps);
    },
    [editedSteps, selectedStepIndex],
  );

  const handleOnCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCode(e.target.value);
    },
    [setCode],
  );

  const isEditingCurrentStepCode = useMemo(
    () => typeof editedSteps[selectedStepIndex]?.code === 'string',
    [editedSteps, selectedStepIndex],
  );

  const handleEditCodeClick = useCallback(() => {
    if (isEditingCurrentStepCode) {
      return;
    }
    setCode(codeString?.raw || '');
  }, [isEditingCurrentStepCode, setCode, codeString?.raw]);

  const updateTaskCapabilitySteps = useUpdateTaskCapabilitySteps();

  const handleSaveAllClick = useCallback(async () => {
    if (!taskId || !capabilityId) {
      return;
    }

    await updateTaskCapabilitySteps.mutateAsync({
      taskId,
      capabilityId,
      request: {edited_steps: editedSteps},
    });
    navigate(`/tasks/${taskId}/edit`);
  }, [taskId, capabilityId, updateTaskCapabilitySteps, editedSteps, navigate]);

  const codeInputDivRef = useRef<HTMLDivElement>(null);

  return (
    <Page title={t('create-edit-task.edit-steps.page-title')}>
      <MobileHeader>
        <h2 className='grow text-2xl text-center md:text-left'>{t('create-edit-task.edit-steps.page-title')}</h2>
      </MobileHeader>
      <div className='size-full flex flex-col items-stretch overflow-y-auto rounded-2xl'>
        <PageContentHeader title={t('create-edit-task.edit-steps.page-title')}>
          <Link draggable={false} to={`/tasks/${taskId}/edit`} className='text-xs text-secondary mr-4'>
            {t('create-edit-task.edit-steps.actions.navigate-to-edit-task')}
          </Link>
        </PageContentHeader>

        <div className='max-w-page-content grow w-full flex flex-col mx-auto gap-y-6 bg-surface-01 px-4 pt-4'>
          <div>
            <Label>{t('create-edit-task.edit-steps.fields.step')}</Label>
            <Select
              value={selectedStepIndex.toString()}
              onChange={handleOnChangeStep}
              options={stepSelectOptions}
              fullWidth={true}
              variant='bordered'
            />
          </div>
          {selectedStep && (
            <>
              <div>
                <Label htmlFor='step-prompt'>{t('create-edit-task.edit-steps.fields.prompt')}</Label>
                <Textarea
                  id='step-prompt'
                  placeholder={t('create-edit-task.fields.description.placeholder')}
                  minRows={4}
                  value={fieldValues.prompt}
                  onValueChange={handleOnPromptChange}
                  disabled={!task?.is_owner}
                />
              </div>
              {selectedStep.type === 'CODE' && (
                <>
                  {!codeString && !isEditingCurrentStepCode ? (
                    <div className='flex flex-col gap-4'>
                      <span className='text-warning'>
                        {t('create-edit-task.edit-steps.code-not-yet-generated-warning')}
                      </span>
                      {task?.is_owner && (
                        <Button
                          variant='primary'
                          className='rounded-xl flex max-w-fit '
                          textSize='base'
                          size='sm'
                          onClick={handleEditCodeClick}
                        >
                          {t('create-edit-task.edit-steps.actions.manually-write-code')}
                          <Pencil1Icon className='text-accent-inverse' />
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div ref={codeInputDivRef} style={{minHeight: codeInputDivRef.current?.clientHeight}}>
                      <div className='flex gap-4 items-center justify-between mb-6'>
                        <span className='text-primary font-bold'>{t('create-edit-task.edit-steps.fields.code')}</span>
                        {editedSteps.at(selectedStepIndex)?.prompt && (
                          <span className='text-warning'>
                            {t('create-edit-task.edit-steps.code-will-be-regenerated-warning')}
                          </span>
                        )}
                        {!isEditingCurrentStepCode && task?.is_owner && (
                          <Button
                            variant='primary'
                            className='rounded-xl flex '
                            textSize='base'
                            size='sm'
                            onClick={handleEditCodeClick}
                          >
                            {t('create-edit-task.edit-steps.actions.edit')}
                            <Pencil1Icon className='text-accent-inverse' />
                          </Button>
                        )}
                      </div>

                      {fieldValues.code !== null && fieldValues.code !== undefined ? (
                        <Textarea
                          id='step-code'
                          minRows={4}
                          value={fieldValues.code}
                          onValueChange={handleOnCodeChange}
                          disabled={!task?.is_owner}
                          autoFocus
                        />
                      ) : (
                        <Markdown>{codeString?.markdown}</Markdown>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className='sticky flex flex-col gap-0 bottom-0 bg-transparent'>
            <div className='w-full h-8 bg-gradient-to-t from-surface-01' />

            <div className='w-full mx-auto bg-surface-01 flex justify-end items-center md:pb-6 pb-3 pt-2'>
              <div className='w-full flex items-center justify-end gap-4 flex-wrap max-w-fit'>
                <Button
                  type='button'
                  variant='primary'
                  size='sm'
                  textSize='base'
                  isLoading={updateTaskCapabilitySteps.isPending}
                  disabled={updateTaskCapabilitySteps.isPending || !task?.is_owner}
                  onClick={handleSaveAllClick}
                >
                  <CheckmarkIcon className='stroke-accent-inverse' />
                  {t('create-edit-task.edit-steps.actions.save-all')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default EditTaskStepsPage;
