import {APP_NAME} from '#/App.tsx';
import Files from '#/components/Files.tsx';
import {MobileHeader} from '#/components/MobileHeader.tsx';
import ScoutSpinner from '#/components/ScoutSpinner.tsx';
import MentionAssistantSelect from '#/components/chat-page/MentionAssistantSelect.tsx';
import MentionedAssistant from '#/components/chat-page/MentionedAssistant.tsx';
import Message from '#/components/chat-page/Message.tsx';
import {ScrollToBottomButton} from '#/components/chat-page/ScrollToBottomButton.tsx';
import ScrollToBottomOnConversationChange from '#/components/chat-page/ScrollToBottomOnConversationChange.tsx';
import ScrollToBottomOnNewUserMessage from '#/components/chat-page/ScrollToBottomOnNewUserMessage.tsx';
import NewConversationPresentation from '#/components/chat-page/new-conversation/NewConversationPresentation.tsx';
import {OnEditSubmitType} from '#/hooks/chat-page/use-edit-message.tsx';
import {useInputFiles} from '#/hooks/chat-page/use-input-files.tsx';
import {useAppSettings} from '#/hooks/use-app-settings.tsx';
import {useConfig} from '#/hooks/use-config';
import Alert from '#/library/alert/Alert.tsx';
import FilePicker from '#/library/file-picker/FilePicker.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {ChatModel} from '#/repositories/config.ts';
import {ReactComponent as PlusIcon} from '#/resources/plus-icon.svg';
import '#/styles/conversation-markdown.css';
import {ArrowUpIcon, Cross1Icon} from '@radix-ui/react-icons';
import 'highlight.js/styles/github.css';
import {FormEvent, FunctionComponent, ReactElement, useCallback, useMemo, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import ScrollToBottom from 'react-scroll-to-bottom';
import TextareaAutosize from 'react-textarea-autosize';

export interface SelectConfig {
  name: string;
  value: string;
  onChange: (value: string) => void;
  options: ChatModel[];
}

export interface ConversationComponentProps {
  messages: ConversationMessage[];
  conversationIsLoading: boolean;
  isLoading: boolean;
  error?: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  message: string;
  onMessageChange: (value: string) => void;
  files?: File[];
  onFileChange?: (files: File[]) => void;
  assistant?: AssistantPublicResponse;
  assistantIsLoading: boolean;
  promptInputRef?: React.RefObject<HTMLTextAreaElement>;
  mentionedAssistant: AssistantPublicResponse | null;
  onMentionedAssistantSelect?: (assistant: AssistantPublicResponse | null) => void;
  onRemoveMentionedAssistant?: () => void;
  conversationId: string | undefined;
  onEditSubmit: OnEditSubmitType;
  ModelSelectComponent?: ReactElement;
  MobileModelSelectComponent?: ReactElement;
  AdditionalComponent?: ReactElement;
}

const ConversationComponent: FunctionComponent<ConversationComponentProps> = ({
  conversationId,
  messages,
  conversationIsLoading,
  isLoading,
  error,
  onSubmit,
  message,
  onMessageChange,
  assistant,
  files = [],
  onFileChange,
  assistantIsLoading,
  promptInputRef,
  mentionedAssistant,
  onMentionedAssistantSelect,
  onRemoveMentionedAssistant,
  onEditSubmit,
  ModelSelectComponent,
  MobileModelSelectComponent,
  AdditionalComponent,
}) => {
  const {t} = useTranslation();
  const {
    config: {features},
  } = useConfig();

  const formRef = useRef<HTMLFormElement>(null);

  const divToScrollRef = useRef<HTMLTableElement>(null);
  const {conversationMaxWidth} = useAppSettings();

  const assistantNameToDisplay = useMemo(() => {
    return assistant?.name ?? APP_NAME;
  }, [assistant?.name]);

  const talkingToAssistantName = useMemo(() => {
    return mentionedAssistant?.name || assistantNameToDisplay;
  }, [assistantNameToDisplay, mentionedAssistant?.name]);

  const [mentionAssistantSelectIsOpen, setMentionAssistantSelectIsOpen] = useState(false);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!(e.metaKey || e.ctrlKey || e.shiftKey) && e.key === 'Enter' && message.trim() !== '') {
        e.preventDefault();
        if (mentionAssistantSelectIsOpen) {
          return;
        }
        if (formRef && formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
        }
      }
    },
    [mentionAssistantSelectIsOpen, message],
  );

  const conversationMessagesToShow = useMemo(() => {
    if (conversationIsLoading) {
      return [];
    }
    return messages.filter(e => e.role !== 'system' && e.role !== 'tool' && e.content);
  }, [conversationIsLoading, messages]);

  const onStarterPrompt = useCallback((prompt: string) => onMessageChange(prompt), [onMessageChange]);

  const {knowledgeFiles, handleAddFiles, handleRemoveFile} = useInputFiles(files, onFileChange);

  const {getRootProps, isDragActive} = useDropzone({onDrop: handleAddFiles, noClick: true});

  const handleOnMessageChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onMessageChange(e.target.value);
    },
    [onMessageChange],
  );

  const handleOnMentionAssistantClose = useCallback(() => {
    promptInputRef?.current?.focus();
  }, [promptInputRef]);

  const scroller = useCallback(
    (values: {
      maxValue: number;
      minValue: number;
      offsetHeight: number;
      scrollHeight: number;
      scrollTop: number;
    }): number => {
      if (!isLoading && values.maxValue > 500) {
        return 0;
      }
      return Infinity;
    },
    [isLoading],
  );

  return (
    <div
      className='size-full relative flex flex-col text-primary grow overflow-hidden transition-opacity data-[isdragactive=true]:opacity-60 @container/conversation focus:outline-none'
      data-isdragactive={isDragActive}
      {...getRootProps()}
    >
      <div
        className='absolute inset-0 z-30 rounded-2xl border-4 border-accent pointer-events-none opacity-0 outline-none transition-opacity data-[isdragactive=true]:opacity-100'
        data-isdragactive={isDragActive}
      />

      <MobileHeader>
        {MobileModelSelectComponent}
        <NavLink
          to='/chat'
          className='aspect-square h-12 rounded-md bg-accent flex justify-center items-center hover:opacity-70 transition-opacity'
        >
          <PlusIcon className='size-7 stroke-accent-inverse' />
        </NavLink>
      </MobileHeader>

      <ScrollToBottom
        className='size-full relative grow overflow-auto md:rounded-none'
        scrollViewClassName='w-full flex flex-col items-stretch overflow-x-hidden'
        followButtonClassName='hidden'
        scroller={scroller}
        mode='bottom'
      >
        <ScrollToBottomOnConversationChange
          conversationId={conversationId}
          conversationMessages={messages}
          conversationIsLoading={conversationIsLoading}
        />

        <ScrollToBottomOnNewUserMessage messages={messages} isLoading={isLoading} />

        {ModelSelectComponent}

        <PageContentHeader maxWidth={conversationMaxWidth} />

        {AdditionalComponent}

        {!conversationId ? (
          <NewConversationPresentation
            assistant={assistant}
            onStarterPrompt={onStarterPrompt}
            maxWidth={conversationMaxWidth}
            assistantIsLoading={assistantIsLoading}
          />
        ) : (
          <div className='grow mt-4 pb-6 px-4' ref={divToScrollRef}>
            <div className='mx-auto' style={{maxWidth: conversationMaxWidth}}>
              <div className='space-y-4 mx-auto md:px-4'>
                {conversationMessagesToShow.map((conversationMessage, index) => (
                  <Message
                    key={index}
                    index={index}
                    message={conversationMessage}
                    conversationAssistantNameToDisplay={assistantNameToDisplay}
                    conversationAssistant={assistant}
                    conversationId={conversationId}
                    onEditSubmit={onEditSubmit}
                  />
                ))}
              </div>

              {isLoading && <ScoutSpinner />}

              {error && <Alert variant='warning'>{error}</Alert>}
            </div>
          </div>
        )}

        <ScrollToBottomButton />

        {features.assistants && onMentionedAssistantSelect && (
          <MentionAssistantSelect
            isOpen={mentionAssistantSelectIsOpen}
            setIsOpen={setMentionAssistantSelectIsOpen}
            message={message}
            className='z-20 absolute inset-x-4 bottom-24 mx-auto'
            style={{maxWidth: conversationMaxWidth}}
            onClose={handleOnMentionAssistantClose}
            onAssistantSelect={onMentionedAssistantSelect}
          />
        )}

        <form className='w-full sticky gap-0 bottom-0 bg-transparent' onSubmit={onSubmit} ref={formRef}>
          <div className='w-full h-8 md:bg-gradient-to-t from-surface-01' />

          <div className='w-full mx-auto bg-surface-01 md:pb-6 pb-4 pt-2 px-4'>
            <div className='mx-auto bg-surface-03 rounded-2xl' style={{maxWidth: conversationMaxWidth}}>
              <Files
                files={knowledgeFiles}
                onRemoveFile={handleRemoveFile}
                className='mb-2 pt-2 max-h-[40vh] overflow-auto'
                filesClassName='bg-surface-02 rounded-md'
              />
              {mentionedAssistant && (
                <MentionedAssistant mentionedAssistant={mentionedAssistant} onRemove={onRemoveMentionedAssistant} />
              )}

              <div className='flex items-center w-full relative gap-3'>
                <TextareaAutosize
                  className='grow text-primary flex focus:outline-none focus:bg-surface-02 p-4 pl-12 md:pl-[70px] pr-[45px] md:pr-[64px] rounded-2xl border border-stroke-main resize-none bg-surface-01 text-lg'
                  value={message}
                  placeholder={t('conversation.prompt-placeholder', {assistant: talkingToAssistantName})}
                  onChange={handleOnMessageChange}
                  maxRows={10}
                  cols={10}
                  onKeyDown={handleKeyDown}
                  autoFocus
                  ref={promptInputRef}
                  data-mentionedassistant={!!mentionedAssistant}
                />

                <div className='absolute left-2'>
                  <FilePicker
                    variant='icon'
                    textSize='base'
                    className='inline size-8 md:p-1 p-1.5'
                    onFilesAdd={handleAddFiles}
                  />
                </div>

                <div className='absolute right-2 md:right-4'>
                  <button
                    className='w-8 aspect-square bg-accent flex justify-center items-center rounded-lg cursor-pointer hover:opacity-70 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed'
                    title={isLoading ? t('conversation.actions.cancel.hint') : t('conversation.actions.send.hint')}
                    disabled={message.trim() === '' && !isLoading}
                  >
                    {isLoading ? (
                      <Cross1Icon className='size-4 stroke-accent-inverse' />
                    ) : (
                      <ArrowUpIcon className='size-4 stroke-accent-inverse' />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ScrollToBottom>
    </div>
  );
};

export default ConversationComponent;
