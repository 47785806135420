import useDownloadBlob from '#/hooks/use-download-blob.tsx';
import {fetchConversation} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {useCallback} from 'react';

const useExportRawConversation = (uuid: string) => {
  const downloadBlob = useDownloadBlob();

  return useCallback(async () => {
    const response = await fetchConversation(uuid);

    const jsonString = JSON.stringify(response.data, null, 2);

    const blob = new Blob([jsonString], {type: 'application/json'});
    downloadBlob(blob, `Scout - ${response.data.title}.json`);
  }, [downloadBlob, uuid]);
};

export default useExportRawConversation;
