import {ConfigUseCase} from '#/hooks/chat-page/use-cases/use-cases.ts';
import {KeyPrefix, ParseKeysByKeyPrefix} from 'i18next';

export const DEFAULT_LANGUAGE = 'en';

export interface SupportLink {
  title: string;
  url: string;
}

export interface LocalizedLink {
  title: string;
  url: string;
}

export interface LocalizedLinks {
  links: Record<string, LocalizedLink[]>;
}

export interface UserAgreement {
  text: Record<string, string>;
  links: Record<string, Record<string, LocalizedLink>>;
}

export interface GoogleLogin {
  clientId: string;
}

export interface BaseOAuthLogin {
  baseUrl: string;
  clientId: string;
}

export interface ExternalService {
  name: string;
  display_name: string;
  icon_url: string;
}

export type ModelTag = ParseKeysByKeyPrefix<KeyPrefix<'translation'>, 'conversation.chat-models.tags'>;

export type ChatModel = {
  id: string;
  description: string;
  displayName: string;
  tags: ModelTag[] | null;
};

export enum AssistantSharing {
  AdminOnly = 'adminOnly',
  Disabled = 'disabled',
  Enabled = 'enabled',
}

export interface WebappConfig {
  application: {
    colors: {
      accent: string;
      accentDark: string;
      accentInverse: string;
    };
    companyName: string;
    supportLinks?: SupportLink[];
    localizedSupportLinks: LocalizedLinks | null;
    userAgreement: UserAgreement | null;
    maxFileSizeInMb?: number;
  };
  features: {
    tasks: boolean;
    assistants: boolean;
    assistantSharing: AssistantSharing;
    imageGeneration: boolean;
    speech: boolean;
    remoteFiles: boolean;
    assistantFiles: boolean;
    externalServices: ExternalService[];
    useCases: ConfigUseCase[];
  };
  integrations: {
    authentication: {
      auth0?: BaseOAuthLogin;
      google?: GoogleLogin;
      okta?: BaseOAuthLogin;
      microsoft?: BaseOAuthLogin;
    };
  };
}
