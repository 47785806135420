import Button from '#/components/Button.tsx';
import {useAuth} from '#/hooks/use-auth.tsx';
import {useConfig} from '#/hooks/use-config.tsx';
import {useErrorMessage} from '#/hooks/use-error-message.tsx';
import {AuthenticationResult, BrowserAuthError, EventType, PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider, useMsal} from '@azure/msal-react';
import {FunctionComponent, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ThreeDots} from 'react-loader-spinner';
import {useNavigate} from 'react-router-dom';

const MicrosoftLoginButton: FunctionComponent<{setErrorMessage: (arg0: string) => void}> = ({setErrorMessage}) => {
  const {config} = useConfig();
  const {instance} = useMsal();
  const {i18n, t} = useTranslation();
  const {errorMessageForError} = useErrorMessage();

  const [isPending, setIsPending] = useState(false);

  const {login} = useAuth();
  const navigate = useNavigate();

  const handleSuccess = useCallback(
    async (response: AuthenticationResult) => {
      try {
        await login({
          oauth_token: response.idToken,
          nonce: null,
          provider: 'microsoft',
        });

        navigate('/chat');
      } catch (error) {
        setErrorMessage(errorMessageForError(error));
      } finally {
        setIsPending(false);
      }
    },
    [errorMessageForError, login, navigate, setErrorMessage],
  );

  useEffect(() => {
    const callbackId = instance.addEventCallback(event => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        handleSuccess(event.payload as AuthenticationResult);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [handleSuccess, instance]);

  const handleLogin = useCallback(async () => {
    setIsPending(true);
    setErrorMessage('');
    if (isPending) return;

    try {
      await instance.loginPopup({
        scopes: ['email', 'User.Read'],
        prompt: 'select_account',
        extraQueryParameters: {
          mkt: i18n.language,
        },
      });
    } catch (error) {
      if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
        setIsPending(false);
        return;
      }
      setErrorMessage(errorMessageForError(error));
      setIsPending(false);
    }
  }, [errorMessageForError, i18n.language, instance, isPending, setErrorMessage]);

  return (
    <Button
      className='bg-accent hover:opacity-70 transition-opacity text-accent-inverse font-bold py-2 px-4 rounded-md w-full'
      disabled={isPending}
      onClick={handleLogin}
    >
      {isPending ? (
        <ThreeDots
          visible={true}
          height='24'
          width='38'
          color={config.application.colors.accentInverse}
          radius='9'
          ariaLabel='three-dots-loading'
          wrapperStyle={{}}
          wrapperClass='justify-center'
        />
      ) : (
        t('login.button')
      )}
    </Button>
  );
};

const MicrosoftLoginWrapper: FunctionComponent<{
  setErrorMessage: (error: string) => void;
  clientId: string;
  baseUrl: string;
}> = ({clientId, baseUrl, ...props}) => {
  const pca = useMemo(() => {
    return new PublicClientApplication({
      auth: {
        clientId,
        authority: baseUrl,
      },
      cache: {
        cacheLocation: 'memoryStorage',
        storeAuthStateInCookie: false,
      },
    });
  }, [baseUrl, clientId]);

  return (
    <MsalProvider instance={pca}>
      <MicrosoftLoginButton {...props} />
    </MsalProvider>
  );
};

export default MicrosoftLoginWrapper;
