import configResource from '#/repositories/assistants-api/requests/fetch-config-resource';
import {AssistantSharing, WebappConfig} from '#/repositories/config';
import React, {ReactNode, createContext, useContext} from 'react';

interface ConfigurationContextType {
  config: WebappConfig;
}

const defaultConfigState: ConfigurationContextType = {
  config: {
    application: {
      colors: {
        accent: '#B5A6FF',
        accentDark: '#C4F24A',
        accentInverse: '#292929',
      },
      companyName: '',
      userAgreement: null,
      localizedSupportLinks: null,
      maxFileSizeInMb: 100,
    },
    features: {
      tasks: false,
      assistants: false,
      assistantSharing: AssistantSharing.Disabled,
      imageGeneration: false,
      speech: false,
      remoteFiles: false,
      assistantFiles: false,
      externalServices: [],
      useCases: [],
    },
    integrations: {
      authentication: {
        auth0: undefined,
        google: undefined,
        okta: undefined,
      },
    },
  },
};

const ConfigurationContext = createContext<ConfigurationContextType>(defaultConfigState);

export const useConfig = () => {
  const context = useContext(ConfigurationContext);
  return context;
};

export const ConfigurationProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const config = configResource.read();

  return (
    <ConfigurationContext.Provider value={{config: config || defaultConfigState.config}}>
      {children}
    </ConfigurationContext.Provider>
  );
};
