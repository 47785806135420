import {UserAvatar} from '#/components/UserAvatar.tsx';
import {UserPublicResponse, fetchUsers} from '#/repositories/assistants-api/requests/fetch-users.ts';
import {FunctionComponent, HTMLAttributes, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {MultiValueProps, NoticeProps, OptionProps, PlaceholderProps, components} from 'react-select';
import AsyncSelect from 'react-select/async';

export interface SharedUsersSelectProps extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  sharedUsers: readonly UserPublicResponse[];
  setSharedUsers: (users: readonly UserPublicResponse[]) => void;
}

const SharedUsersSelect: FunctionComponent<SharedUsersSelectProps> = ({className, sharedUsers, setSharedUsers}) => {
  const promiseOptions = useCallback(async (inputValue: string) => {
    const response = await fetchUsers(inputValue);
    return response.data;
  }, []);

  return (
    <AsyncSelect<UserPublicResponse, true>
      name='shared-users'
      className={className}
      unstyled
      classNames={{
        control: () => 'border-2 border-stroke-main rounded-xl p-3',
        multiValue: () => 'bg-surface-03 m-1 rounded text-sm',
        multiValueLabel: () => 'p-1 mr-1',
        multiValueRemove: () => 'hover:bg-red-500 rounded p-1 ',
        option: ({isFocused}) => `p-2 ${isFocused ? 'bg-surface-03' : ''}`,
        placeholder: () => 'opacity-50 text-secondary',
        menu: () => 'bg-surface-02 mt-1 border border-stroke-main shadow-md rounded py-1',
        clearIndicator: () => 'hover:text-red-500 cursor-pointer mr-4',
      }}
      value={sharedUsers}
      openMenuOnFocus={false}
      openMenuOnClick={false}
      onChange={setSharedUsers}
      loadOptions={promiseOptions}
      getOptionValue={option => option.email}
      components={{
        Option: CustomOption,
        MultiValue: CustomMultiValue,
        NoOptionsMessage: CustomNoOptionsMessage,
        LoadingMessage: CustomLoadingMessage,
        LoadingIndicator: undefined,
        Placeholder: CustomPlaceholder,
      }}
      closeMenuOnSelect={false}
      cacheOptions={true}
      isMulti
    />
  );
};

const CustomOption: FunctionComponent<OptionProps<UserPublicResponse, true>> = ({data, ...props}) => {
  return (
    <components.Option data={data} {...props}>
      <div className='flex items-center gap-2'>
        <UserAvatar
          pictureUrl={data.picture_url}
          firstName={data.first_name}
          lastName={data.last_name}
          className='size-10'
        />
        <span>{`${data.first_name} ${data.last_name}`}</span>
      </div>
    </components.Option>
  );
};

const CustomMultiValue: FunctionComponent<MultiValueProps<UserPublicResponse, true>> = ({data, ...props}) => {
  return (
    <components.MultiValue data={data} {...props}>
      <div className='flex items-center gap-2'>
        <UserAvatar
          pictureUrl={data.picture_url}
          firstName={data.first_name}
          lastName={data.last_name}
          className='size-5 text-[0.5rem]'
        />
        <span>{`${data.first_name} ${data.last_name}`}</span>
      </div>
    </components.MultiValue>
  );
};
const CustomNoOptionsMessage: FunctionComponent<NoticeProps<UserPublicResponse, true>> = ({...props}) => {
  const {t} = useTranslation();
  return (
    <components.NoOptionsMessage {...props}>{t('model-visibility.shared-users.no-users')}</components.NoOptionsMessage>
  );
};

const CustomLoadingMessage: FunctionComponent<NoticeProps<UserPublicResponse, true>> = ({...props}) => {
  const {t} = useTranslation();
  return <components.LoadingMessage {...props}>{t('model-visibility.shared-users.loading')}</components.LoadingMessage>;
};

const CustomPlaceholder: FunctionComponent<PlaceholderProps<UserPublicResponse, true>> = ({...props}) => {
  const {t} = useTranslation();
  return <components.Placeholder {...props}>{t('model-visibility.shared-users.placeholder')}</components.Placeholder>;
};

export default SharedUsersSelect;
