import ModelSelectField from '#/components/ModelSelectField.tsx';
import Page from '#/components/Page.tsx';
import {Sender} from '#/components/chat-page/ChatPage.tsx';
import ConversationComponent from '#/components/chat-page/ConversationComponent.tsx';
import TaskPreviewPage from '#/components/tasks/create-edit-task/task-debugger/TaskPreviewPage.tsx';
import {OnEditSubmitType} from '#/hooks/chat-page/use-edit-message.tsx';
import {useChatModelsQuery} from '#/hooks/query/chat-models.tsx';
import {useTaskQuery} from '#/hooks/query/tasks.tsx';
import {useDefaultModelId} from '#/hooks/use-default-model-id.tsx';
import {useSetMessagesOnStreamChunks} from '#/hooks/use-set-messages-on-stream-chunks.tsx';
import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {TaskResponse} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {
  StreamTaskDebuggerChatCompletionRequest,
  streamTaskDebuggerChatCompletion,
} from '#/repositories/assistants-api/requests/stream-task-debugger-chat-completion.ts';
import {ReactComponent as GearIcon} from '#/resources/gear-icon.svg';
import {FormEvent, FunctionComponent, memo, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';

type TaskDebuggerPageParams = {
  taskId: string;
};

export const TASK_DEBUGGER_CONVERSATION_ID = 'task-debugger-conversation';

const TaskDebuggerPage: FunctionComponent = () => {
  const {t} = useTranslation();

  const {taskId} = useParams<TaskDebuggerPageParams>();

  const {defaultModelId, setDefaultModelId} = useDefaultModelId();
  const chatModels = useChatModelsQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const taskQuery = useTaskQuery({taskId, shouldPoll: false});

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<ConversationMessage[]>([
    {
      role: 'assistant',
      content: t('create-edit-task.task-debugger.welcome-message'),
    },
  ]);

  const abortControllerRef = useRef<AbortController>();

  const handleStopStreaming = useCallback(() => {
    setIsLoading(false);
  }, []);

  const setMessagesOnStreamChunks = useSetMessagesOnStreamChunks(setMessages);

  const handleStreamChatCompletion = useCallback(
    (newConversation: ConversationMessage[]) => {
      setIsLoading(true);

      const request: StreamTaskDebuggerChatCompletionRequest = {
        messages: newConversation,
        model: defaultModelId || chatModels[0]?.id,
      };

      const abortController = new AbortController();

      streamTaskDebuggerChatCompletion(
        taskId || '',
        request,
        chunks => setMessagesOnStreamChunks(chunks, newConversation, () => null),
        error => {
          if (!abortControllerRef.current?.signal.aborted) {
            setError(error.toString());
            setIsLoading(false);
          }
        },
        () => {
          taskQuery.refetch();
          handleStopStreaming();
        },
        abortController.signal,
      );

      abortControllerRef.current = abortController;
    },
    [chatModels, defaultModelId, handleStopStreaming, setMessagesOnStreamChunks, taskId, taskQuery],
  );

  const sendTaskDebugMessages = useCallback(
    async (newMessages: ConversationMessage[]) => {
      const filteredMessages = messages.filter(
        message => message.content === undefined || typeof message.content === 'string',
      );
      const newConversation = [...filteredMessages, ...newMessages];

      setError('');
      setMessage('');

      handleStreamChatCompletion(newConversation);
      setMessages(newConversation);
    },
    [handleStreamChatCompletion, messages],
  );

  const handleEditSubmit: OnEditSubmitType = useCallback(
    (_, updatedMessage) => {
      if (!updatedMessage) {
        return;
      }

      let count = -1;
      let absoluteIndex = -1;
      for (let index = 0; index < messages.length; index++) {
        const message = messages[index];
        const role = message.role;
        if (role !== 'system' && role !== 'tool' && !!message.content) {
          count += 1;
          if (count === updatedMessage.messageIndex) {
            absoluteIndex = index;
            break;
          }
        }
      }

      const updatedMessages = messages.slice(0, absoluteIndex + 1);
      updatedMessages[absoluteIndex] = {
        role: 'user',
        content: updatedMessage.editedMessage,
      };

      setMessages(updatedMessages);
      handleStreamChatCompletion(updatedMessages);
    },
    [handleStreamChatCompletion, messages],
  );

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isLoading) {
        abortControllerRef.current?.abort();
        handleStopStreaming();
        return;
      }

      setIsLoading(true);

      if (!defaultModelId) {
        return;
      }

      const newMessage: ConversationMessage = {
        role: 'user' as Sender,
        content: message,
      };

      await sendTaskDebugMessages([newMessage]);
    },
    [defaultModelId, handleStopStreaming, isLoading, message, sendTaskDebugMessages],
  );

  return (
    <Page title={t('create-edit-task.task-debugger.page-title')}>
      <div className='flex h-full overflow-hidden'>
        <div className='w-1/2'>
          <ConversationComponent
            conversationId={TASK_DEBUGGER_CONVERSATION_ID}
            messages={messages}
            conversationIsLoading={false}
            isLoading={isLoading}
            message={message}
            onMessageChange={setMessage}
            onSubmit={onSubmit}
            error={error}
            assistantIsLoading={false}
            mentionedAssistant={null}
            onEditSubmit={handleEditSubmit}
            ModelSelectComponent={
              <ModelSelectField
                chatModels={chatModels}
                selectedModelId={defaultModelId}
                setSelectedModelId={setDefaultModelId}
                className='md:absolute md:top-0 md:left-0 md:z-40 md:flex md:m-5'
              />
            }
            MobileModelSelectComponent={
              <ModelSelectField
                chatModels={chatModels}
                selectedModelId={defaultModelId}
                setSelectedModelId={setDefaultModelId}
                className='md:hidden'
              />
            }
            AdditionalComponent={<TaskButton taskQueryData={taskQuery.data} />}
          />
        </div>
        <div className='w-1/2'>{taskId && <TaskPreviewPage taskQuery={taskQuery} />}</div>
      </div>
    </Page>
  );
};

const TaskButton: FunctionComponent<{taskQueryData: TaskResponse | undefined}> = memo(({taskQueryData}) => {
  const {t} = useTranslation();

  return (
    taskQueryData && (
      <NavLink
        to={`/tasks/${taskQueryData?.id}/edit`}
        className='absolute top-4 right-4 flex gap-2 h-12 rounded-lg bg-surface-02 p-4 items-center z-10 hover:opacity-70 transition-opacity group'
      >
        <GearIcon className='group-hover:rotate-[30deg] transition stroke-primary' />
        <span className='font-bold'>{t('create-edit-task.task-debugger.edit-button')}</span>
      </NavLink>
    )
  );
});

export default TaskDebuggerPage;
