import Markdown from '#/components/Markdown.tsx';
import {FunctionComponent, useMemo} from 'react';

const PythonCodeMarkdown: FunctionComponent<{code?: string}> = ({code}) => {
  const pythonCode = useMemo(() => {
    return `\`\`\`python\n${code ?? ''}`;
  }, [code]);

  return <Markdown>{pythonCode}</Markdown>;
};

export default PythonCodeMarkdown;
