import {useConfig} from '#/hooks/use-config';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {Security} from '@okta/okta-react';
import {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';

export const OKTA_LOGIN_CALLBACK_URL = '/okta/login/callback';
export const OKTA_LOGIN_URL = '/okta/login';

const OktaProvider = (props: {children: ReactNode}) => {
  const {config} = useConfig();
  const {okta} = config.integrations.authentication;

  if (!okta) {
    throw new Error('Okta login not configured');
  }

  const navigate = useNavigate();
  const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(OKTA_LOGIN_URL, window.location.origin));
  };

  const oktaAuth = new OktaAuth({
    issuer: `https://${okta.baseUrl}/oauth2/default`,
    clientId: okta.clientId,
    redirectUri: `${window.location.origin}${OKTA_LOGIN_CALLBACK_URL}`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  });

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {props.children}
    </Security>
  );
};

export default OktaProvider;
