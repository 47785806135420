import AssistantAvatar from '#/components/assistants/AssistantAvatar.tsx';
import {AssistantUseCase, ConfigUseCase, ConversationUseCase} from '#/hooks/chat-page/use-cases/use-cases.ts';
import useUseCases, {isAssistantUseCase, isConfigUseCase} from '#/hooks/chat-page/use-cases/use-use-cases.tsx';
import {useAssistants} from '#/hooks/use-assistants.tsx';
import {useAuth} from '#/hooks/use-auth.tsx';
import {HeaderSubtitle, HeaderTitle} from '#/library/page-content-header/PageContentHeader.tsx';
import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {ReactComponent as ConversationIcon} from '#/resources/conversation-icon.svg';
import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const UseCasesView: FunctionComponent<{maxWidth: string | undefined}> = ({maxWidth}) => {
  const useCases = useUseCases();

  return (
    <div className='size-full flex flex-col mt-5 md:mt-0'>
      <Header maxWidth={maxWidth} />

      <div className='grow flex items-center'>
        <div className='px-4 flex gap-4 w-full overflow-x-auto items-stretch '>
          {useCases.map(useCase =>
            isAssistantUseCase(useCase) ? (
              <AssistantUseCaseCard key={useCase.id} useCase={useCase} />
            ) : isConfigUseCase(useCase) ? (
              <ConfigUseCaseCard key={useCase.id} useCase={useCase} />
            ) : (
              <ConversationUseCaseCard key={useCase.id} useCase={useCase} />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export const Header: FunctionComponent<{maxWidth: string | undefined}> = ({maxWidth}) => {
  const {t} = useTranslation();

  const {user} = useAuth();

  return (
    <div className='md:mx-auto px-4 md:px-5' style={{maxWidth}}>
      <HeaderTitle title={t('conversation.use-cases.title', {name: user?.firstName})} />
      <HeaderSubtitle subtitle={t('conversation.use-cases.subtitle')} />
    </div>
  );
};

const ConfigUseCaseCard: FunctionComponent<{useCase: ConfigUseCase}> = ({useCase}) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const translatedInstructions = useMemo(() => {
    const languageKey = i18n.language as keyof ConfigUseCase;
    return useCase[languageKey];
  }, [i18n.language, useCase]);

  const handleClick = useCallback(() => {
    const newMessages: ConversationMessage[] = [
      {
        role: 'user',
        content: translatedInstructions,
      },
      {
        role: 'system',
        content: useCaseSystemPrompt,
      },
    ];

    navigate('/chat', {state: {newMessages}});
  }, [navigate, translatedInstructions]);

  return (
    <UseCaseCardButtonContainer onClick={handleClick}>
      <UseCaseCardIcon IconComponent={ConversationIcon} />
      <UseCaseCardTitle title={translatedInstructions} />
      <UseCaseCardSubtitle subtitle={t('conversation.use-cases.types.conversation')} />
    </UseCaseCardButtonContainer>
  );
};

const AssistantUseCaseCard: FunctionComponent<{useCase: AssistantUseCase}> = ({useCase}) => {
  const navigate = useNavigate();

  const {assistants} = useAssistants();
  const assistant = useMemo(() => {
    return assistants.find(assistant => assistant.id === useCase.assistantId);
  }, [assistants, useCase.assistantId]);

  const handleClick = useCallback(() => {
    const newMessages: ConversationMessage[] = [
      {
        role: 'user',
        content: useCase.instructions,
      },
      {
        role: 'system',
        content: useCaseSystemPrompt,
      },
    ];

    navigate(`/assistants/${useCase.assistantId}/chat`, {state: {newMessages}});
  }, [navigate, useCase.assistantId, useCase.instructions]);

  if (!assistant) return null;

  return (
    <UseCaseCardButtonContainer onClick={handleClick}>
      <AssistantAvatar
        containerClassName='size-20'
        className='rounded-xl text-3xl'
        src={assistant.avatar_url}
        assistantName={assistant.name}
      />
      <UseCaseCardTitle title={useCase.instructions} />
      <UseCaseCardSubtitle subtitle={assistant?.name} />
    </UseCaseCardButtonContainer>
  );
};

const ConversationUseCaseCard: FunctionComponent<{useCase: ConversationUseCase}> = ({useCase}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const translatedInstructions = t(`conversation.use-cases.titles.${useCase.id}`);

  const handleClick = useCallback(() => {
    const newMessages: ConversationMessage[] = [
      {
        role: 'user',
        content: translatedInstructions,
      },
      {
        role: 'system',
        content: useCaseSystemPrompt,
      },
    ];

    navigate('/chat', {state: {newMessages}});
  }, [navigate, translatedInstructions]);

  return (
    <UseCaseCardButtonContainer onClick={handleClick}>
      <UseCaseCardIcon IconComponent={ConversationIcon} />
      <UseCaseCardTitle title={translatedInstructions} />
      <UseCaseCardSubtitle subtitle={t('conversation.use-cases.types.conversation')} />
    </UseCaseCardButtonContainer>
  );
};

const UseCaseCardButtonContainer: FunctionComponent<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className='group/use-case-card w-56 py-10 px-8 gap-2 shrink-0 bg-surface-02 hover:bg-surface-03 transition-colors rounded-2xl flex flex-col justify-between text-left'
    >
      {children}
    </button>
  );
};

const UseCaseCardIcon: FunctionComponent<{IconComponent: typeof ConversationIcon}> = ({IconComponent}) => {
  return (
    <IconComponent className='group-hover/use-case-card:bg-surface-02 transition-colors size-20 p-5 bg-surface-03 stroke-primary rounded-xl ' />
  );
};

const UseCaseCardTitle: FunctionComponent<{title: string}> = ({title}) => {
  return <div className='text-base font-semibold overflow-hidden'>{title}</div>;
};

const UseCaseCardSubtitle: FunctionComponent<{subtitle: string}> = ({subtitle}) => {
  return <div className='text-xs font-light opacity-60'>{subtitle}</div>;
};

const useCaseSystemPrompt =
  'If the user asks for assistance about a file, a text, or any element and there are no elements in the conversation, you must ask for the required element.';

export default UseCasesView;
