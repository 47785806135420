import ScoutAPI from '#/repositories/assistants-api/api';
import {setAccessToken, setRefreshToken} from '#/repositories/tokens_repository.ts';
import {AxiosResponse} from 'axios';

export type LoginProvider = 'google' | 'okta' | 'auth0' | 'microsoft';

export type LoginRequest = {
  oauth_token: string;
  nonce: string | null;
  provider: LoginProvider;
};

export type LoginResponse = {
  access_token: string;
  refresh_token: string;
};

export const login = async (request: LoginRequest) => {
  const response = await ScoutAPI.post<LoginRequest, AxiosResponse<LoginResponse>>('/auth/login/', request);
  setAccessToken(response.data.access_token);
  setRefreshToken(response.data.refresh_token);
};
