import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {UserCustomPromptResponse} from '#/repositories/assistants-api/requests/fetch-user-custom-prompt.ts';
import {AxiosResponse} from 'axios';

export type UpdateUserCustomPromptRequest = {
  prompt: string;
};

export const updateUserCustomPrompt = (
  request: UpdateUserCustomPromptRequest,
): Promise<AxiosResponse<UpdateUserCustomPromptRequest>> => {
  return ScoutAPI.put<UpdateUserCustomPromptRequest, AxiosResponse<UserCustomPromptResponse>>(
    '/users/custom-prompt',
    request,
  );
};
