import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {clean_html} from '#/repositories/browser-extension/clean_html.ts';
import {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

type BrowserExtensionEvent = {
  data: {message: {action: string; content: string; message: string | undefined; url: string; title: string}};
};

const BrowserExtensionMessageAction = 'scout-browser-extension-message-action';

const SendClosePopupAction = 'scout-close-popup-action';

export const useSendMessageFromBrowserExtension = (
  sendMessage: (newMessages: ConversationMessage[], title?: string | undefined) => Promise<void>,
) => {
  const buildExtensionMessages = useBuildExtensionMessages();

  useEffect(() => {
    const listener = (event: Event) => {
      const eventData = (event as unknown as BrowserExtensionEvent).data;
      if (eventData?.message?.action === BrowserExtensionMessageAction) {
        sendMessage(
          buildExtensionMessages(
            eventData.message.content,
            eventData.message.message,
            eventData.message.url,
            eventData.message.title,
          ),
          eventData.message.title,
        );
      } else if (eventData?.message?.action === SendClosePopupAction) {
        window.close();
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [buildExtensionMessages, sendMessage]);
};

export const useBuildExtensionMessages = () => {
  const {t} = useTranslation();

  return useCallback(
    (content: string, message: string | undefined, url: string, title: string): ConversationMessage[] => {
      return [
        {
          role: 'system',
          content: `You are an assistant that answers using a cleaned version of a web page DOM.
          Here is cleaned DOM of the page or the selected text on the page:
          ${clean_html(content)}

          URL of the page:
          ${url}

          Title of the page:
          ${title}`,
        },
        {
          role: 'user',
          content: message || t('conversation.default-extension-user-message'),
        },
      ];
    },
    [t],
  );
};
