import {ToolStatusLoaderProps} from '#/components/tool-loaders/ToolStatusLoader';
import {useConfig} from '#/hooks/use-config';
import {ThreeCircles} from 'react-loader-spinner';

const DefaultToolLoader = (props: ToolStatusLoaderProps) => {
  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      <span>{props.toolName}</span>
    </div>
  );
};

export default DefaultToolLoader;

export const DefaultLoader = () => {
  const {config} = useConfig();
  const {
    application: {colors},
  } = config;

  return (
    <ThreeCircles
      visible={true}
      height='20'
      width='20'
      color={colors.accent}
      ariaLabel='tool-status-loader'
      wrapperStyle={{display: 'inline-block'}}
    />
  );
};
