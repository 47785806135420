import {ImageDownloadButton} from '#/components/chat-page/GeneratedImageComponent.tsx';
import {useSignedUrlQuery} from '#/hooks/query/signed-url.tsx';
import {useIsProtectedUrl} from '#/hooks/use-is-protected-url.tsx';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {twMerge} from 'tailwind-merge';

export interface ProtectedImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  containerClassName?: string;
  animate?: boolean;
  title?: string;
  downloadButton?: boolean;
}

const ProtectedImg = ({
  className,
  containerClassName,
  animate,
  title,
  downloadButton,
  alt,
  src,
  ...props
}: ProtectedImgProps) => {
  const {t} = useTranslation();
  const isProtectedUrl = useIsProtectedUrl(src);
  const signedUrlQuery = useSignedUrlQuery(src, isProtectedUrl);
  const signedUrl = isProtectedUrl ? signedUrlQuery.data?.url : src;
  const signedUrlIsPending = isProtectedUrl ? signedUrlQuery.isPending : false;
  const [imageIsPending, setImageIsPending] = useState(true);

  const handleImageOnLoad = useCallback(() => {
    setImageIsPending(false);
  }, []);

  return (
    <div
      className={twMerge(
        'group/protected-image relative inline-block group data-[imagepending=true]:size-generated-image data-[imagepending=true]:aspect-square',
        containerClassName,
      )}
      data-imagepending={imageIsPending}
    >
      {(!signedUrl || signedUrlIsPending || imageIsPending) && (
        <div className={twMerge('absolute inset-0 animate-pulse bg-surface-03 aspect-square', className)} />
      )}

      {signedUrlQuery.isError && (
        <div
          className={twMerge('absolute inset-0 bg-warning text-center overflow-hidden text-warning-inverse', className)}
        >
          {t('conversation.loading-image-failure', {src})}
        </div>
      )}

      {signedUrl && (
        <img
          className={twMerge(
            'max-h-full max-w-full object-contain rounded-2xl transition-opacity group-data-[imagepending=true]/protected-image:opacity-0 data-[animate=true]:duration-200',
            className,
          )}
          {...props}
          alt={alt}
          src={signedUrl}
          onLoad={handleImageOnLoad}
        />
      )}

      {downloadButton && <ImageDownloadButton src={src} />}
    </div>
  );
};

export default memo(ProtectedImg);
