import ExecuteExternalServicesCodeFunctionPermissionMessage from '#/components/chat-page/function-permissions/ExecuteExternalServicesCodeFunctionPermissionMessage.tsx';
import {MetadataToolCall} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {StreamToolStatus} from '#/utils/streaming-utils.ts';
import {FunctionComponent, ReactNode, useMemo} from 'react';

export interface FunctionPermissionMessageComponent {
  (props: {toolCall: MetadataToolCall}): ReactNode;
}

const functionMessageComponents: Record<string, FunctionPermissionMessageComponent> = {
  execute_external_services_code: ExecuteExternalServicesCodeFunctionPermissionMessage,
};

const FunctionPermissionMessage: FunctionComponent<{toolCall: StreamToolStatus}> = ({toolCall}) => {
  return useMemo(() => {
    const Component = functionMessageComponents[toolCall.tool_name];
    return <Component toolCall={toolCall} />;
  }, [toolCall]);
};

const FunctionPermissionMessageView: FunctionComponent<{toolCall: StreamToolStatus}> = ({toolCall}) => {
  return <FunctionPermissionMessage toolCall={toolCall} />;
};

export default FunctionPermissionMessageView;
