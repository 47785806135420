import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {AxiosResponse} from 'axios';

export type UpdateAdminConfigRequest = {
  config: string;
};

export type UpdateAdminConfigResponse = {
  message: string;
};

export const updateAdminConfig = (
  request: UpdateAdminConfigRequest,
): Promise<AxiosResponse<UpdateAdminConfigResponse>> => {
  return ScoutAPI.post<UpdateAdminConfigRequest, AxiosResponse<UpdateAdminConfigResponse>>('/config/', request);
};
