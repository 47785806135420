import {useAuthenticatedQuery} from '#/hooks/query/base';
import {useToasts} from '#/hooks/use-toasts';
import {chatCompletion, ChatCompletionRequest} from '#/repositories/assistants-api/requests/chat-completion';
import {useTranslation} from 'react-i18next';

export const useChatCompletionQuery = (request: ChatCompletionRequest, id: string) => {
  const {addToast} = useToasts();
  const {t} = useTranslation();

  const {data, error} = useAuthenticatedQuery({
    queryKey: ['chat-completion-request', id],
    queryFn: () => chatCompletion(request),
    enabled: request.messages.length > 0 && request.model.length > 0,
  });

  if (error) {
    addToast(t('errors.unexpected'), 'error');
  }

  return data?.data ?? [];
};
