import {Button, ButtonProps} from '#/library/button/Button.tsx';
import {ReactComponent as PaperClipIcon} from '#/resources/paperclip.svg';
import React, {FunctionComponent, memo, useRef} from 'react';
import {useTranslation} from 'react-i18next';

export interface FilePickerProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'accept'>,
    Omit<ButtonProps, 'variant' | 'size'> {
  onFilesAdd: (files: File[]) => void;
  variant: 'icon' | 'text';
}

export const FilePicker: FunctionComponent<FilePickerProps> = memo(
  ({onFilesAdd, accept, className, variant, textSize, ...props}) => {
    const {t} = useTranslation();

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      fileInputRef.current?.click();
    };

    const handleOnFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        return;
      }
      const files = Array.from(event.target.files);

      onFilesAdd(files);

      if (fileInputRef?.current) {
        fileInputRef.current.value = '';
      }
    };

    return (
      <div className='flex items-center size-full text-primary'>
        {variant === 'icon' && (
          <>
            <button
              {...props}
              className={`flex items-center text-accent-inverse justify-center hover:opacity-50 transition-opacity ${className}`}
              onClick={handleClick}
            >
              <PaperClipIcon className='stroke-primary' />
            </button>
            <div className='w-px h-6 bg-surface-03 md:ml-2' />
          </>
        )}
        {variant === 'text' && (
          <Button
            variant='primary'
            size='sm'
            textSize={textSize}
            className={`flex items-center ${className}`}
            onClick={handleClick}
            {...props}
          >
            <span>{t('file-picker.actions.upload-files')}</span>
          </Button>
        )}
        <input
          ref={fileInputRef}
          onChange={handleOnFileChange}
          type='file'
          accept={accept}
          className='hidden'
          tabIndex={-1}
          multiple
        />
      </div>
    );
  },
);

export default FilePicker;
