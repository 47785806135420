import {ChatModel} from '#/repositories/config';
import {useEffect} from 'react';

export const useSetSelectedModelWhenNotRecognized = (
  chatModels: ChatModel[],
  selectedModelId: string | undefined,
  setSelectedModelId: (value: string | undefined) => void,
) => {
  useEffect(() => {
    if (!chatModels.length) return;

    if (!selectedModelId || !chatModels.some(model => model.id === selectedModelId)) {
      setSelectedModelId(chatModels[0].id);
    }
  }, [chatModels, selectedModelId, setSelectedModelId]);
};
