import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {CreateConversationRequest} from '#/repositories/assistants-api/requests/create-conversation.ts';
import {AssistantResponse, ContentRetrievingStrategy} from '#/repositories/assistants-api/requests/fetch-assistant.ts';
import {ModelVisibilityRequest} from '#/repositories/assistants-api/requests/visibility.ts';
import {AxiosResponse} from 'axios';

export type CreateAssistantRequest = {
  name: string;
  description: string;
  instructions: string;
  prompt_starters: string[];
  visibility: ModelVisibilityRequest;
  avatar_url?: string;
  use_system_prompt: boolean;
  allowed_functions?: string[];
  allowed_external_services?: string[];
  content_retrieving_strategy: ContentRetrievingStrategy;
};

export const createAssistant = (request: CreateAssistantRequest): Promise<AxiosResponse<AssistantResponse>> => {
  return ScoutAPI.post<CreateConversationRequest, AxiosResponse<AssistantResponse>>('/assistants/', request);
};
