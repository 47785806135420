import ScoutSpinner from '#/components/ScoutSpinner';
import {Sender} from '#/components/chat-page/ChatPage';
import {FunctionPermissionDetailsWrapper} from '#/components/chat-page/function-permissions/FunctionPermissionDetailsView.tsx';
import PythonCodeMarkdown from '#/components/chat-page/tools-used/PythonCodeMarkdown.tsx';
import {ToolUsedItemProps} from '#/components/chat-page/tools-used/ToolUsedItem.tsx';
import {useChatCompletionQuery} from '#/hooks/query/chat-completion';
import {useChatModelsQuery} from '#/hooks/query/chat-models';
import {ChatCompletionResponse} from '#/repositories/assistants-api/requests/chat-completion';
import {contentPartsToString} from '#/utils/conversation-utils.ts';
import {castJson} from '#/utils/json-utils.ts';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export interface ExecuteExternalServicesCodeToolArguments {
  code: string;
  service_name: string;
}

const ExecuteExternalServicesCodeFunctionPermissionDetails: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const toolArguments = useMemo(() => {
    return castJson<ExecuteExternalServicesCodeToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  const models = useChatModelsQuery();
  const {t} = useTranslation();

  const chatCompletionRequest = useMemo(
    () => ({
      messages: toolArguments?.code
        ? [
            {
              role: 'system' as Sender,
              content: t(
                'conversation.function-permissions.functions.execute_external_services_code.chat-completion-explain-code',
              ),
            },
            {
              role: 'user' as Sender,
              content: toolArguments.code,
            },
          ]
        : [],
      model: models[0] ? models[0].id : '',
    }),
    [models, t, toolArguments?.code],
  );

  const chatCompletionExplanation = useChatCompletionQuery(chatCompletionRequest, toolArguments?.code ?? '');
  const chatCompletionMessages = (chatCompletionExplanation as ChatCompletionResponse)?.messages;
  const lastMessage = chatCompletionMessages?.at(-1);

  const isLoaded = useMemo(() => {
    return !!chatCompletionMessages;
  }, [chatCompletionMessages]);

  return (
    <div>
      <div className='pb-6 flex'>
        <div className={`justify-center transition-opacity duration-300 ${isLoaded ? 'opacity-100' : 'opacity-0'}`}>
          {isLoaded &&
            lastMessage?.content &&
            (Array.isArray(lastMessage.content) ? contentPartsToString(lastMessage.content) : lastMessage.content)}
        </div>
        {!isLoaded && (
          <>
            <ScoutSpinner />
            <span className='pl-2'>{t('conversation.function-permissions.explaining')}</span>{' '}
          </>
        )}
      </div>
      <FunctionPermissionDetailsWrapper>
        <PythonCodeMarkdown code={toolArguments?.code} />
      </FunctionPermissionDetailsWrapper>
    </div>
  );
};

export default ExecuteExternalServicesCodeFunctionPermissionDetails;
