import {AssistantUseCase} from '#/hooks/chat-page/use-cases/use-cases.ts';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {getRandomItems} from '#/utils/array-utils.ts';

export const pickAssistantUseCases = (assistants: AssistantPublicResponse[]): AssistantUseCase[] => {
  const assistantsWithPromptStarters = assistants.filter(assistant => assistant.prompt_starters.length > 0);

  const pickedAssistants = getRandomItems(assistantsWithPromptStarters, 10);

  const assistantUseCases: AssistantUseCase[] = pickedAssistants.map(assistant => {
    const prompt = getRandomItems(assistant.prompt_starters, 1)[0];

    return {
      id: `assistant-${assistant.id}`,
      instructions: prompt,
      assistantId: assistant.id,
    };
  });

  return assistantUseCases;
};
