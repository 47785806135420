import {useAuthenticatedQuery} from '#/hooks/query/base.tsx';
import {useAuth} from '#/hooks/use-auth.tsx';
import {useErrorMessage} from '#/hooks/use-error-message.tsx';
import {useToasts} from '#/hooks/use-toasts.tsx';
import {checkAuth} from '#/repositories/assistants-api/requests/check-auth.ts';
import {createApiToken} from '#/repositories/assistants-api/requests/create-api-token.ts';
import {externalLogout, prepareExternalLogin} from '#/repositories/assistants-api/requests/external-services.ts';
import {fetchApiToken} from '#/repositories/assistants-api/requests/fetch-api-token.ts';
import {fetchApiTokens} from '#/repositories/assistants-api/requests/fetch-api-tokens.ts';
import {fetchUserConnectedServices} from '#/repositories/assistants-api/requests/fetch-user-connected-services.ts';
import {revokeApiToken} from '#/repositories/assistants-api/requests/revoke-api-token.ts';
import {UpdateApiTokenRequest, updateApiToken} from '#/repositories/assistants-api/requests/update-api-token.ts';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';

export const useCheckAuthQuery = () =>
  useQuery({
    queryKey: ['check-auth'],
    queryFn: checkAuth,
    retry: false,
  });

export const useConnectedServicesQuery = () => {
  return useAuthenticatedQuery({
    queryKey: ['connected-services'],
    queryFn: fetchUserConnectedServices,
  });
};

export const usePrepareExternalLoginMutation = () => {
  const {addToast} = useToasts();
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['prepare-external'],
    mutationFn: prepareExternalLogin,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'connected-services']});
    },
    onError: (error: AxiosError) => {
      const errorMessage = (error.response?.data as {error: string})?.error || t('errors.unexpected');
      addToast(errorMessage, 'error');
    },
  });
};

export const useExternalLogoutMutation = () => {
  const {addToast} = useToasts();
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['external-logout'],
    mutationFn: externalLogout,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'connected-services']});
    },
    onError: (error: AxiosError) => {
      const errorMessage = (error.response?.data as {error: string})?.error || t('errors.unexpected');
      addToast(errorMessage, 'error');
    },
  });
};

export const useApiTokensQuery = () => {
  return useAuthenticatedQuery({
    queryKey: ['api-tokens'],
    queryFn: fetchApiTokens,
  });
};

export const useApiTokenQuery = (tokenId?: string) => {
  return useAuthenticatedQuery({
    queryKey: ['api-token', tokenId],
    queryFn: () => fetchApiToken(tokenId || ''),
  });
};

export const useCreateApiTokenMutation = () => {
  const {addToast} = useToasts();
  const queryClient = useQueryClient();
  const {errorMessageForError} = useErrorMessage();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['create-api-token'],
    mutationFn: createApiToken,
    onSuccess: data => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'api-tokens']});
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};

export const useUpdateApiTokenMutation = (tokenId: string) => {
  const {addToast} = useToasts();
  const queryClient = useQueryClient();
  const {errorMessageForError} = useErrorMessage();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['update-api-token'],
    mutationFn: (request: UpdateApiTokenRequest) => updateApiToken(tokenId, request),
    onSuccess: data => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'api-tokens']});
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};

export const useRevokeApiTokenMutation = () => {
  const {addToast} = useToasts();
  const queryClient = useQueryClient();
  const {errorMessageForError} = useErrorMessage();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['revoke-api-token'],
    mutationFn: revokeApiToken,
    onSuccess: data => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'api-tokens']});
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};
