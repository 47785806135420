import {Sender} from '#/components/chat-page/ChatPage.tsx';
import ScoutAPI from '#/repositories/assistants-api/api';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {FunctionPermission} from '#/repositories/assistants-api/requests/stream-chat-completion.ts';
import {AxiosResponse} from 'axios';
import {ReactNode} from 'react';

export interface MetadataToolCall {
  tool_name: string;
  arguments: string;
}

export interface MessageMetadata {
  assistant_id?: string;
  files?: {name: string; content_type: string}[];
  tool_calls?: MetadataToolCall[];
  function_permissions?: FunctionPermission[];
}

export interface ToolCallFunction {
  name: string;
  arguments: string;
}

export interface MessageToolCall {
  id: string;
  type: string;
  function: ToolCallFunction;
}

export type ConversationMessageContentPartParam =
  | {
      type: 'text';
      text: string;
    }
  | {
      type: 'image';
      data: string;
      content_type: string;
    }
  | {
      type: 'audio';
      data: string;
      format: string;
    };

export interface ConversationMessage {
  role: Sender;
  content?: string | ReactNode | ConversationMessageContentPartParam[];
  metadata?: MessageMetadata | null;
  tool_call_id?: string | null;
  tool_calls?: MessageToolCall[];
}

export interface ConversationResponse {
  id: string;
  title: string;
  payload: ConversationMessage[];
  updated_at: string;
  assistant?: AssistantPublicResponse;
  model: string | null;
}

export const fetchConversation = (uuid: string): Promise<AxiosResponse<ConversationResponse>> => {
  return ScoutAPI.get<null, AxiosResponse<ConversationResponse>>(`/conversations/${uuid}`);
};
