import {Sender} from '#/components/chat-page/ChatPage.tsx';
import {ConversationMessage, MessageMetadata} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {} from '#/repositories/assistants-api/requests/stream-chat-completion.ts';

export const KEEP_ALIVE_CHARS_REGEX = /:\n\n/g;

export type StreamChatCompletionOnMessageEvent = (streamChunks: StreamChunk[]) => void;

export type StreamToolStatus = {
  status: 'PREPARING' | 'CALLING';
  tool_name: string;
  arguments: string;
  call_id: string;
};

export type StreamError = {
  code: string;
  message: string;
};

export type StreamChunk = {
  role?: Sender;
  content?: string;
  tool_call_id?: string;
  metadata?: MessageMetadata;
  tool_calls?: StreamToolStatus[];
  finish_reason?: 'STOP' | 'ERROR' | 'TOOL_CALL' | 'REQUIRES_CONFIRMATION';
  error: StreamError;
};

export const handleChatStreamResponse = (
  response: Response,
  onMessage: StreamChatCompletionOnMessageEvent,
  onError: (error: string) => void,
  onStreamEnd: () => void,
) => {
  if (!response.ok) {
    onError('An unexpected error occurred');
    return;
  }
  if (response.body === null) {
    onError('ReadableStream not supported in this browser');
    return;
  }
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  const accumulatedChunks: StreamChunk[] = [];

  return new ReadableStream({
    async start(controller) {
      try {
        let accumulatedCurrentData = '';
        while (true) {
          const {done, value} = await reader.read();
          if (done) {
            onStreamEnd();
            break;
          }

          const currentData =
            accumulatedCurrentData + decoder.decode(value, {stream: true}).replace(KEEP_ALIVE_CHARS_REGEX, '');
          accumulatedCurrentData = '';
          // When data does not finish with a \n, we must accumulate it as the JSON chunk is cut in two
          if (!currentData.endsWith('\n')) {
            accumulatedCurrentData += currentData;
            continue;
          }
          if (!currentData) continue;

          const chunks = currentData.split('\n');
          const receivedStreamChunks = chunks.reduce(
            (acc, chunk) => (!chunk ? acc : [...acc, JSON.parse(chunk)]),
            [] as StreamChunk[],
          );

          receivedStreamChunks.forEach(receivedChunk => {
            if (receivedChunk.finish_reason === 'STOP') {
              return;
            }

            const correspondingChunkIndex = accumulatedChunks.findLastIndex(accumulatedChunk =>
              isSameChunk(accumulatedChunk, receivedChunk),
            );

            if (correspondingChunkIndex === -1) {
              accumulatedChunks.push(receivedChunk);
              return;
            }

            const newContent = `${accumulatedChunks[correspondingChunkIndex].content || ''}${receivedChunk.content || ''}`;

            accumulatedChunks.splice(correspondingChunkIndex, 1);
            accumulatedChunks.push({
              ...receivedChunk,
              content: newContent === '' ? undefined : newContent,
            });
          });

          onMessage(accumulatedChunks);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        onError('An unexpected error occurred, try refreshing the page.');
      }
    },
  });
};

export const isSameChunk = (chunk1: StreamChunk, chunk2: StreamChunk): boolean => {
  return (
    chunk1.role === chunk2.role &&
    chunk1.tool_call_id === chunk2.tool_call_id &&
    chunk1.tool_calls?.map(tool_call => tool_call.call_id).join('') ===
      chunk2.tool_calls?.map(tool_call => tool_call.call_id).join('')
  );
};

export const convertStreamChunkToConversationMessage = (streamChunk: StreamChunk): ConversationMessage => {
  return {
    role: streamChunk.role,
    content: streamChunk.content,
    metadata: streamChunk.metadata,
    tool_call_id: streamChunk.tool_call_id,
    tool_calls: streamChunk.tool_calls
      ?.filter(stream_tool_call => stream_tool_call.status === 'CALLING')
      .map(stream_tool_call => ({
        id: stream_tool_call.call_id,
        type: 'function',
        function: {name: stream_tool_call.tool_name, arguments: stream_tool_call.arguments},
      })),
  } as ConversationMessage;
};

export const streamChunkIsRelatedToToolCallId = (streamChunk: StreamChunk | undefined, toolCallId: string): boolean => {
  return (
    streamChunk !== undefined &&
    streamChunk.finish_reason !== 'REQUIRES_CONFIRMATION' &&
    (streamChunk.tool_call_id === toolCallId ||
      streamChunk.tool_calls?.some(tool_call => tool_call.call_id === toolCallId) ||
      false)
  );
};
