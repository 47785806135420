import {KeyPrefix, ParseKeysByKeyPrefix} from 'i18next';

interface UseCase {
  id: string;
}

export interface ConversationUseCase extends UseCase {
  id: ParseKeysByKeyPrefix<KeyPrefix<'translation'>, 'conversation.use-cases.titles'>;
}

export interface AssistantUseCase extends UseCase {
  assistantId: string;
  instructions: string;
}

export interface ConfigUseCase extends UseCase {
  id: string;
  fr: string;
  en: string;
}

export type AnyUseCase = ConversationUseCase | AssistantUseCase | ConfigUseCase;

export const genericUseCases: ConversationUseCase[] = [
  {
    id: 'spreadsheet-hours',
  },
  {
    id: 'metadata-seo-2024',
  },
  {
    id: 'reformulate-paragraph',
  },
  {
    id: 'find-cv',
  },
  {
    id: 'extract-statistics',
  },
  {
    id: 'find-ingredient-substitute',
  },
  {
    id: 'create-training-program',
  },
  {
    id: 'prepare-inventory',
  },
  {
    id: 'read-pdf',
  },
  {
    id: 'explain-in-simple-terms',
  },
  {
    id: 'octalysis',
  },
  {
    id: 'find-typos',
  },
  {
    id: 'extract-statistics-article',
  },
  {
    id: 'question',
  },
];

export const googleIntegrationUseCases: ConversationUseCase[] = [
  {
    id: 'google-calendar-link',
  },
];

export const slackIntegrationUseCases: ConversationUseCase[] = [
  {
    id: 'open-pr-slack-reminder',
  },
];

export const imageGenerationUseCases: ConversationUseCase[] = [
  {
    id: 'generate-image-neoclassical',
  },
  {
    id: 'generate-image-organigram',
  },
  {
    id: 'generate-image-logo',
  },
];
