import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {useKeyPressEvent} from 'react-use';

const useSetSelectedIndexOnArrowsKeyPress = (
  isOpen: boolean,
  selectedIndex: number,
  filteredAssistants: AssistantPublicResponse[],
  setSelectedIndex: (index: number) => void,
) => {
  useKeyPressEvent('ArrowUp', e => {
    if (!isOpen) return;

    e.preventDefault();
    if (selectedIndex === 0) return;
    setSelectedIndex(selectedIndex - 1);
  });

  useKeyPressEvent('ArrowDown', e => {
    if (!isOpen) return;

    e.preventDefault();
    if (selectedIndex === filteredAssistants.length - 1) return;
    setSelectedIndex(selectedIndex + 1);
  });
};

export default useSetSelectedIndexOnArrowsKeyPress;
