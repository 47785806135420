import {useAppSettings} from '#/hooks/use-app-settings.tsx';
import {useAssistants} from '#/hooks/use-assistants';
import {useConversations} from '#/hooks/use-conversations.tsx';
import {useMemo} from 'react';

export const useDisplayedAssistants = () => {
  const {assistants} = useAssistants();
  const {conversations} = useConversations();
  const {numberOfAssistantsToShow} = useAppSettings();

  const displayedAssistants = useMemo(() => {
    const latestConversations = conversations.slice(0, 100);

    const assistantsUsedCounts = latestConversations.reduce(
      (counts, conversation) => {
        if (conversation.assistant_id) {
          counts[conversation.assistant_id] = (counts[conversation.assistant_id] || 0) + 1;
        }
        return counts;
      },
      {} as Record<string, number>,
    );

    const assistantsSortedByUsage = [...assistants].sort((assistant1, assistant2) => {
      return (assistantsUsedCounts[assistant2.id] || 0) - (assistantsUsedCounts[assistant1.id] || 0);
    });

    return assistantsSortedByUsage.slice(0, numberOfAssistantsToShow);
  }, [assistants, conversations, numberOfAssistantsToShow]);

  return {displayedAssistants, numberOfAssistantsToShow};
};
