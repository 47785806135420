import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {Howl} from 'howler';

export type StreamSpeechSynthesisRequest = {
  conversationId: string;
  messageIndex: number;
};

export const playStreamingSpeechSynthesis = async (
  request: StreamSpeechSynthesisRequest,
  onPlayerCreated: (howler: Howl) => void,
  onPlayerPlay: () => void,
  onPlayerEnd: () => void,
) => {
  await new Promise<void>((resolve, reject) => {
    const queryParams = `?conversation_id=${encodeURIComponent(request.conversationId)}&message_index=${request.messageIndex}&stream=true`;

    const howler = new Howl({
      src: [`${ScoutAPI.defaults.baseURL}/audio/speech${queryParams}`],
      format: ['mp3'],
      autoplay: true,
      loop: false,
      volume: 1,
      html5: true,
    });

    onPlayerCreated(howler);

    howler.on('play', () => {
      onPlayerPlay();
      resolve();
    });
    howler.on('loaderror', () => {
      reject();
    });
    howler.on('playerror', () => {
      reject();
    });

    howler.on('end', () => {
      onPlayerEnd();
    });

    howler.on('stop', () => {
      onPlayerEnd();
    });

    howler.play();
  });
};
