import {castJson} from '#/utils/json-utils.ts';
import {AxiosError} from 'axios';
import {KeyPrefix, ParseKeysByKeyPrefix} from 'i18next';
import {ParseKeys} from 'node_modules/i18next';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export const useErrorMessage = () => {
  const {t} = useTranslation();

  const convertErrorMessageToUserMessage = useCallback(
    (errorString: string, defaultErrorMessageKey: ParseKeys | null = 'errors.unexpected'): string => {
      if (errorString.includes('context_length_exceeded')) {
        return t('errors.context-length-exceeded');
      }
      if (errorString.toLowerCase().includes('image exceeds max pixels')) {
        return t('errors.image-exceeds-max-pixels');
      }
      const parsedError = castJson<Record<string, string>>(errorString);
      if (parsedError !== null) {
        const {error_code, ...args} = parsedError;
        const errorCode = error_code as ParseKeysByKeyPrefix<KeyPrefix<'translation'>, 'errors'>;

        return String(t(`errors.${errorCode}`, args as never));
      }
      return defaultErrorMessageKey ? t(defaultErrorMessageKey) : errorString.toString();
    },
    [t],
  );

  const errorMessageForError = useCallback(
    (error: unknown | AxiosError, defaultErrorMessageKey: ParseKeys | null = 'errors.unexpected') => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          return t('errors.unauthorized');
        } else if (error.response?.data?.error) {
          return convertErrorMessageToUserMessage(error.response?.data?.error, defaultErrorMessageKey);
        }
      }
      return t(defaultErrorMessageKey ?? 'errors.unexpected');
    },
    [convertErrorMessageToUserMessage, t],
  );

  return {convertErrorMessageToUserMessage, errorMessageForError};
};
