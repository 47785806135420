import {fetchSignedUrl} from '#/repositories/assistants-api/requests/fetch-signed-url.ts';
import environment from '#/repositories/environment';
import {useQuery} from '@tanstack/react-query';

const REFETCH_INTERVAL = 60 * 60 * 1000;

export const useSignedUrlQuery = (src?: string, enabled?: boolean) =>
  useQuery({
    queryKey: ['signed-url', src],
    queryFn: async () => {
      const response = await fetchSignedUrl(src || '');
      return {url: response.data.url.startsWith('/') ? environment.baseApiUrl + response.data.url : response.data.url};
    },
    refetchInterval: REFETCH_INTERVAL,
    enabled,
    retry: false,
  });
