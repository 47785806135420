import {ThemePreference, useTheme} from '#/hooks/use-theme';
import {DesktopIcon, MoonIcon, SunIcon} from '@radix-ui/react-icons';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import {ComponentProps, FunctionComponent, useCallback} from 'react';

export type ThemeSelectorDropdownProps = Partial<ComponentProps<typeof ToggleGroup.Root>> & {
  variant?: 'expanded' | 'collapsed';
};

export const ThemeSelectorDropdown: FunctionComponent<ThemeSelectorDropdownProps> = ({variant = 'expanded'}) => {
  const {themePreference, setThemePreference} = useTheme();

  const onValueChange = useCallback(
    (value: ThemePreference) => {
      setThemePreference(value);
    },
    [setThemePreference],
  );

  const ToggleGroupItem: FunctionComponent<{'value': string; 'aria-label': string; 'children': React.ReactNode}> = ({
    value,
    'aria-label': ariaLabel,
    children,
  }) => (
    <ToggleGroup.Item
      className='hover:bg-surface-03 data-[state=on]:bg-surface-01 flex items-center justify-center rounded-lg focus:outline-none p-2 data-[variant=expanded]:py-0.5'
      value={value}
      aria-label={ariaLabel}
      data-variant={variant}
    >
      {children}
    </ToggleGroup.Item>
  );

  return (
    <div className='flex justify-center self-center items-center'>
      <ToggleGroup.Root
        className='flex data-[variant=expanded]:inline-flex flex-col data-[variant=expanded]:flex-row p-1 bg-surface-03 rounded-xl'
        type='single'
        value={themePreference}
        onValueChange={onValueChange}
        aria-label='Theme selection'
        data-variant={variant}
      >
        <ToggleGroupItem value='light' aria-label='Light theme' children={<SunIcon color='var(--color-primary)' />} />
        <ToggleGroupItem value='dark' aria-label='Dark theme' children={<MoonIcon color='var(--color-primary)' />} />
        <ToggleGroupItem
          value='system'
          aria-label='System theme'
          children={<DesktopIcon color='var(--color-primary)' />}
        />
      </ToggleGroup.Root>
    </div>
  );
};
