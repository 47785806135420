import FunctionPermissionDetailsView from '#/components/chat-page/function-permissions/FunctionPermissionDetailsView.tsx';
import FunctionPermissionMessageView from '#/components/chat-page/function-permissions/FunctionPermissionMessageView.tsx';
import Alert from '#/library/alert/Alert.tsx';
import {Button} from '#/library/button/Button.tsx';
import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {FunctionPermission} from '#/repositories/assistants-api/requests/stream-chat-completion.ts';
import {StreamToolStatus} from '#/utils/streaming-utils.ts';
import {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

export type OnRespondFunctionPermissionFn = (
  functionPermissions: FunctionPermission[],
  newConversation: ConversationMessage[],
) => void;

const FunctionPermissionAlert: FunctionComponent<{
  toolCall: StreamToolStatus;
  onRespond: OnRespondFunctionPermissionFn;
  conversation: ConversationMessage[];
}> = ({toolCall, onRespond, conversation}) => {
  const {t} = useTranslation();
  const [buttonClicked, setButtonClicked] = useState<'allowConversation' | 'allowOnce' | 'dontAllow'>();

  const handleRespond = useCallback(
    (functionPermissions: FunctionPermission[]) => {
      // We remove the last message because it is the confirmation component message
      const trimmedConversation = conversation.slice(0, -1);
      onRespond(functionPermissions, trimmedConversation);
    },
    [conversation, onRespond],
  );

  const onAllowConversationClick = useCallback(() => {
    setButtonClicked('allowConversation');
    handleRespond([{function_name: toolCall.tool_name}]);
  }, [handleRespond, toolCall.tool_name]);

  const onAllowOnceClick = useCallback(() => {
    setButtonClicked('allowOnce');
    handleRespond([{call_id: toolCall.call_id}]);
  }, [handleRespond, toolCall.call_id]);

  const handleDontAllow = useCallback(() => {
    setButtonClicked('dontAllow');
    handleRespond([{call_id: toolCall.call_id, denied: true}]);
  }, [handleRespond, toolCall.call_id]);

  return (
    <Alert variant='surface'>
      <div className='flex flex-col gap-4'>
        <FunctionPermissionMessageView toolCall={toolCall} />
        <div>
          <FunctionPermissionDetailsView toolCall={toolCall} />
        </div>
        <div className='flex justify-center gap-4 flex-wrap'>
          <Button
            variant='primary'
            size='sm'
            textSize='base'
            onClick={onAllowConversationClick}
            isLoading={buttonClicked === 'allowConversation'}
          >
            {t('conversation.function-permissions.allow-conversation')}
          </Button>
          <Button
            variant='primary'
            size='sm'
            textSize='base'
            onClick={onAllowOnceClick}
            isLoading={buttonClicked === 'allowOnce'}
          >
            {t('conversation.function-permissions.allow-once')}
          </Button>
          <Button
            variant='cancel'
            size='sm'
            textSize='base'
            onClick={handleDontAllow}
            isLoading={buttonClicked === 'dontAllow'}
          >
            {t('conversation.function-permissions.dont-allow')}
          </Button>
        </div>
      </div>
    </Alert>
  );
};

export default FunctionPermissionAlert;
