import {TaskRunLogVariables} from '#/components/tasks/TaskRunLogVariables';
import {useTaskRunsQuery} from '#/hooks/query/tasks';
import {Button} from '#/library/button/Button';
import {TaskRunResponse, TaskRunStatus, TaskRunStatusEnum} from '#/repositories/assistants-api/requests/fetch-task-run';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import {ReactComponent as CheckmarkCircled} from 'src/resources/checkmark-circled-icon.svg';
import {ReactComponent as ClockCircled} from 'src/resources/clock-circled-icon.svg';
import {ReactComponent as CrossCircled} from 'src/resources/cross-circled-icon.svg';

const TaskRunStatusIcon: FunctionComponent<{status?: TaskRunStatus}> = ({status}) => {
  if (status === TaskRunStatusEnum.IN_QUEUE || status === TaskRunStatusEnum.RUNNING) return <ClockCircled />;
  if (status === 'FAILED' || status === 'STOPPED') return <CrossCircled className='stroke-red-500' />;
  return <CheckmarkCircled />;
};

export const TaskRunCard: FunctionComponent<{run: TaskRunResponse}> = ({run}) => {
  const {i18n} = useTranslation();
  return (
    <Link
      draggable={false}
      to={`/tasks/${run.task_id}/runs/${run.id}`}
      className='relative flex flex-col justify-center overflow-hidden text-balance rounded-xl bg-surface-02 p-4 hover:bg-surface-03 transition-colors'
    >
      <div className='flex items-center justify-between'>
        <div className='flex gap-2 items-center'>
          <div>
            <TaskRunStatusIcon status={run.status} />
          </div>

          <p className='font-bold text-sm text-primary'>{run.task_description}</p>
        </div>

        <div className='text-xs font-bold text-secondary shrink-0'>
          <ReactTimeAgo date={new Date(run.updated_at)} locale={i18n.language} />
        </div>
      </div>
      {run.final_result && <TaskRunLogVariables variables={run.final_result} />}
    </Link>
  );
};

export const StatusButton: FunctionComponent<{
  icon: React.ReactNode;
  label?: string;
  onClick: () => void;
  selected: boolean;
}> = ({icon, label, onClick, selected}) => (
  <Button
    className='size-12 shrink-0 gap-2 flex justify-center rounded-lg bg-surface-02 cursor-pointer text-primary hover:opacity-70 transition border-2 border-stroke-main data-[selected=true]:bg-surface-03'
    variant='primary'
    size='lg'
    textSize='base'
    onClick={onClick}
    data-selected={selected}
  >
    {icon}
    {label && <span>{label}</span>}
  </Button>
);

const LatestTasksSection: FunctionComponent = () => {
  const {t} = useTranslation();
  const [status, setStatus] = useState<TaskRunStatus | undefined>(undefined);
  const {data: taskRuns, refetch} = useTaskRunsQuery({taskId: undefined, shouldPoll: false, status});

  useEffect(() => {
    refetch();
  }, [status, refetch]);

  const runs = taskRuns ?? [];

  const handleStatusChange = (newStatus?: TaskRunStatus) => {
    setStatus(newStatus);
  };

  return (
    <div className='max-w-page-content grow w-full flex flex-col mx-auto gap-y-3 px-4'>
      <div className='flex justify-between items-center'>
        <h3 className='font-bold mb-2'>{t('tasks.latest-tasks.title')}</h3>
        <div className='flex gap-2'>
          <StatusButton
            icon={null}
            label={t('tasks.latest-tasks.all-button')}
            onClick={() => handleStatusChange(undefined)}
            selected={status === undefined}
          />
          <StatusButton
            icon={<CheckmarkCircled />}
            onClick={() => handleStatusChange(TaskRunStatusEnum.SUCCESS)}
            selected={status === TaskRunStatusEnum.SUCCESS}
          />
          <StatusButton
            icon={<CrossCircled className='stroke-red-500' />}
            onClick={() => handleStatusChange(TaskRunStatusEnum.FAILED)}
            selected={status === TaskRunStatusEnum.FAILED}
          />
          <StatusButton
            icon={<ClockCircled />}
            onClick={() => handleStatusChange(TaskRunStatusEnum.IN_QUEUE)}
            selected={status === TaskRunStatusEnum.IN_QUEUE}
          />
        </div>
      </div>
      {runs.map(run => (
        <TaskRunCard key={run.id} run={run} />
      ))}
    </div>
  );
};

export default LatestTasksSection;
