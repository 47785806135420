import AppRoutes from '#/AppRoutes.tsx';
import AppProviders from '#/components/AppProviders';
import {Toaster} from 'sonner';

const AppContentWrapper = () => {
  return (
    <>
      <AppProviders>
        <Toaster richColors position='top-right' duration={5000} closeButton />
        <AppRoutes />
      </AppProviders>
    </>
  );
};

export default AppContentWrapper;
