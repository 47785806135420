import AssistantsAPI from '#/repositories/assistants-api/api';
import {TaskResponse} from '#/repositories/assistants-api/requests/fetch-tasks';
import {AxiosResponse} from 'axios';

export type EditedStep = {
  prompt: string | null;
  code: string | null;
};

export type UpdateTaskCapabilityStepsRequest = {
  edited_steps: (EditedStep | null)[];
};

export const updateTaskCapabilitySteps = (
  taskId: string,
  capabilityId: string,
  request: UpdateTaskCapabilityStepsRequest,
): Promise<AxiosResponse<TaskResponse>> => {
  return AssistantsAPI.put<UpdateTaskCapabilityStepsRequest, AxiosResponse<TaskResponse>>(
    `/tasks/${taskId}/capabilities/${capabilityId}/steps`,
    request,
  );
};
