import {ChatPageState} from '#/components/chat-page/ChatPage.tsx';
import {ConversationMessage, ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {UseQueryResult} from '@tanstack/react-query';
import {useEffect, useRef} from 'react';

export const useSendMessagesOnNavigateWithNewMessages = (
  state: ChatPageState,
  onSendMessages: (messages: ConversationMessage[]) => void,
  conversationQuery: UseQueryResult<ConversationResponse, Error>,
) => {
  const didStartStream = useRef(false);

  useEffect(() => {
    if (!didStartStream.current && !!state?.newMessages?.length) {
      didStartStream.current = true;
      onSendMessages(state.newMessages);
    }
  }, [onSendMessages, state?.newMessages]);

  useEffect(() => {
    didStartStream.current = false;
  }, [conversationQuery.data]);
};
