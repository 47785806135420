import {useErrorMessage} from '#/hooks/use-error-message.tsx';
import {useToasts} from '#/hooks/use-toasts';
import {fetchAdminConfig} from '#/repositories/assistants-api/requests/fetch-admin-config';
import {updateAdminConfig} from '#/repositories/assistants-api/requests/update-admin-config';
import {
  ValidateAdminConfigRequest,
  validateAdminConfig,
} from '#/repositories/assistants-api/requests/validate-admin-config';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {t} from 'i18next';

export const useAdminConfigQuery = () =>
  useQuery({
    queryKey: ['get-admin-config'],
    queryFn: async () => {
      const response = await fetchAdminConfig();
      return response.data;
    },
  });

export const useValidateAdminConfigMutation = () => {
  const queryClient = useQueryClient();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['validate-admin-config'],
    mutationFn: async (data: ValidateAdminConfigRequest) => validateAdminConfig(data),
    onSuccess: () => {
      addToast(t('admin.toasts.success.validate'), 'success');
      queryClient.invalidateQueries({queryKey: ['validate-admin-config']});
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error, 'admin.toasts.errors.invalid-config'), 'error');
    },
  });
};

export const useUpdateAdminConfigMutation = () => {
  const queryClient = useQueryClient();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['update-admin-config'],
    mutationFn: async (data: ValidateAdminConfigRequest) => updateAdminConfig(data),
    onSuccess: () => {
      addToast(t('admin.toasts.success.save'), 'success');
      queryClient.invalidateQueries({queryKey: ['update-admin-config']});
    },
    onError: (error: AxiosError) => {
      addToast(errorMessageForError(error, 'admin.toasts.errors.invalid-config'), 'error');
    },
  });
};
