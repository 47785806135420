import {handleRefreshToken} from '#/repositories/assistants-api/api';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

const isLocalhost = window.location.hostname === 'localhost';

const getCookieOptions = (overrides = {}) => {
  const sameSiteValue: 'Lax' | 'Strict' | 'None' = isLocalhost ? 'Lax' : 'Strict';

  return {
    expires: 31,
    secure: !isLocalhost,
    sameSite: sameSiteValue,
    domain: window.location.hostname,
    ...overrides,
  };
};

export const getAccessToken = () => {
  return Cookies.get(ACCESS_TOKEN_STORAGE_KEY);
};

export const getRefreshToken = () => {
  return Cookies.get(REFRESH_TOKEN_STORAGE_KEY);
};

export const setAccessToken = (token: string) => {
  Cookies.set(ACCESS_TOKEN_STORAGE_KEY, token, getCookieOptions());
};

export const setRefreshToken = (token: string) => {
  Cookies.set(REFRESH_TOKEN_STORAGE_KEY, token, getCookieOptions());
};

export const removeAllTokens = () => {
  Cookies.remove(ACCESS_TOKEN_STORAGE_KEY, getCookieOptions());
  Cookies.remove(REFRESH_TOKEN_STORAGE_KEY, getCookieOptions());
};

interface TokenPayload {
  sub: string;
  first_name: string;
  last_name: string;
  picture_url?: string;
  is_admin?: boolean;
}

export const getTokenPayload = async (): Promise<TokenPayload | null> => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return null;
  }

  const decodedPayload = jwtDecode<TokenPayload>(accessToken);

  interface TokenPayload {
    sub: string;
    first_name: string;
    last_name: string;
    picture_url?: string;
    is_admin?: boolean;
    [key: string]: string | boolean | undefined;
  }

  const requiredFields = ['first_name', 'last_name'];

  const hasAllRequiredFields = requiredFields.every(
    field => field in decodedPayload && decodedPayload[field] !== null && decodedPayload[field] !== undefined,
  );

  if (!hasAllRequiredFields) {
    await handleRefreshToken();
    const newAccessToken = getAccessToken();

    if (!newAccessToken) {
      return null;
    }

    const newDecodedPayload = jwtDecode<TokenPayload>(newAccessToken);
    return newDecodedPayload;
  }

  return decodedPayload;
};
