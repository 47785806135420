import {ModelSelectFieldItem} from '#/components/ModelSelectFieldItem.tsx';
import {ChatModel} from '#/repositories/config.ts';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {ChevronDownIcon} from '@radix-ui/react-icons';
import {FunctionComponent, memo} from 'react';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

export interface ModelSelectFieldProps {
  className?: string;
  chatModels: ChatModel[];
  selectedModelId: string | undefined;
  setSelectedModelId: (selectedModelId: string | undefined) => void;
}

const ModelSelectField: FunctionComponent<ModelSelectFieldProps> = ({
  className,
  chatModels,
  selectedModelId,
  setSelectedModelId,
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        className={`flex items-center justify-between gap-2 px-4 py-3 w-fit text-base font-bold text-primary rounded-lg text-center truncate focus:outline-none bg-surface-02 hover:shadow transition-shadow shrink-0 ${className}`}
      >
        {chatModels.find(chatModel => chatModel.id === selectedModelId)?.displayName}
        <ChevronDownIcon />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className='w-[300px] border-stroke-main border bg-surface-02 p-2 rounded-xl shadow-lg text-primary z-30 max-h-[60vh] overflow-y-auto'
          sideOffset={5}
        >
          <DropdownMenu.RadioGroup value={selectedModelId} onValueChange={setSelectedModelId}>
            {chatModels.map((chatModel, index) => (
              <ScrollIntoViewIfNeeded
                active={chatModel.id === selectedModelId}
                options={{behavior: 'instant', block: 'start'}}
                key={chatModel.id}
              >
                <ModelSelectFieldItem key={chatModel.id} chatModel={chatModel} />
                {index !== chatModels.length - 1 && <DropdownMenu.Separator className='my-3 mx-2 h-px bg-surface-03' />}
              </ScrollIntoViewIfNeeded>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default memo(ModelSelectField);
