import {useConfig} from '#/hooks/use-config.tsx';
import {useDisplayedAssistants} from '#/hooks/use-displayed-assistants.tsx';
import {ActionBar as ActionBarNoData} from '#/library/actionbar/ActionBar.tsx';
import {FunctionComponent, HTMLAttributes} from 'react';

const ActionBar: FunctionComponent<Pick<HTMLAttributes<HTMLDivElement>, 'className'>> = ({className = ''}) => {
  const {
    config: {features},
  } = useConfig();

  return (
    <ActionBarWrapper className={className}>
      {features.assistants ? <ActionBarAssistants /> : <ActionBarNoAssistants />}
    </ActionBarWrapper>
  );
};

const ActionBarWrapper: FunctionComponent<Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'children'>> = ({
  className = '',
  children,
}) => {
  return <div className={`w-sidebar-actionbar-width shrink-0 p-4 hidden md:block ${className}`}>{children}</div>;
};

const ActionBarAssistants: FunctionComponent = () => {
  const {displayedAssistants} = useDisplayedAssistants();

  return <ActionBarNoData assistants={displayedAssistants} />;
};

const ActionBarNoAssistants: FunctionComponent = () => <ActionBarNoData />;

export default ActionBar;
