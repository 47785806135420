import ScoutAPI from '#/repositories/assistants-api/api';
import {AxiosResponse} from 'axios';

export type AllowedAvatarContentTypesResponse = {
  allowed_content_types: string[];
};

export const fetchAssistantAvatarAllowedContentTypes = (): Promise<
  AxiosResponse<AllowedAvatarContentTypesResponse>
> => {
  return ScoutAPI.get<null, AxiosResponse<AllowedAvatarContentTypesResponse>>(
    '/assistants/avatar/upload/content-types/',
  );
};
