import {DefaultLoader} from '#/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from '#/components/tool-loaders/ToolStatusLoader';
import {useTranslation} from 'react-i18next';

const GenerateImageContentToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      <span>{t('tools.generate-image.in-progress-text')}</span>
    </div>
  );
};

export default GenerateImageContentToolLoader;
