import ScoutAPI from '#/repositories/assistants-api/api';
import {AxiosResponse} from 'axios';

export type FileStatus = 'FAILED' | 'IN_QUEUE' | 'PROCESSED' | 'PROCESSING';

export type AssistantFileResponse = {
  id: string;
  filename: string;
  filedescription: string;
  status: FileStatus;
  content_type: string;
};

export const fetchAssistantFiles = (uuid: string): Promise<AxiosResponse<AssistantFileResponse[]>> => {
  return ScoutAPI.get<null, AxiosResponse<AssistantFileResponse[]>>(`/assistants/${uuid}/files`);
};

export type AddFileToAssistantRequest = {
  assistantId: string;
  file: File;
};

export type FileUploadResponse = {
  message: string;
  assistant_id: string;
};

export const addFileToAssistant = (request: AddFileToAssistantRequest): Promise<AxiosResponse<FileUploadResponse>> => {
  const formData = new FormData();
  formData.append('file', request.file);
  return ScoutAPI.post(`/assistants/${request.assistantId}/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export type DeleteAssistantFileRequest = {
  assistantUuid: string;
  fileUuid: string;
};

export type FileDeleteResponse = {
  message: string;
  assistant_id: string;
};
export const deleteAssistantFile = ({
  assistantUuid,
  fileUuid,
}: DeleteAssistantFileRequest): Promise<AxiosResponse<FileDeleteResponse>> => {
  return ScoutAPI.delete<null, AxiosResponse<FileDeleteResponse>>(`/assistants/${assistantUuid}/files/${fileUuid}`);
};

export type EditFileInformationRequest = {
  file_name: string;
  file_description: string;
};

export type EditFileInformationResponse = {
  message: string;
  assistant_id: string;
};

export const updateFileInformation = (
  assistantId: string,
  fileId: string,
  request: EditFileInformationRequest,
): Promise<AxiosResponse<EditFileInformationResponse>> => {
  return ScoutAPI.put(`/assistants/${assistantId}/files/${fileId}`, request);
};
