import {useConfig} from '#/hooks/use-config';
import {DEFAULT_LANGUAGE} from '#/repositories/config';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {QuestionMarkCircledIcon} from '@radix-ui/react-icons';
import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

const HelpButton = () => {
  const {
    config: {
      application: {supportLinks, localizedSupportLinks},
    },
  } = useConfig();

  const {i18n} = useTranslation();

  const localSupportLinks = useMemo(() => {
    const currentLanguage = i18n.language;
    const links = localizedSupportLinks?.links;

    if (links) {
      if (links.hasOwnProperty(currentLanguage) && links[currentLanguage].length > 0) {
        return localizedSupportLinks.links[currentLanguage];
      } else if (links.hasOwnProperty(DEFAULT_LANGUAGE) && links[DEFAULT_LANGUAGE].length > 0) {
        return localizedSupportLinks.links[DEFAULT_LANGUAGE];
      }
    }
    return supportLinks;
  }, [localizedSupportLinks, supportLinks, i18n.language]);

  return !localSupportLinks || !localSupportLinks.length ? null : (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className='size-6 shrink-0 rounded-xl flex justify-center items-center hover:opacity-70 transition-opacity'>
          <QuestionMarkCircledIcon className='size-full text-secondary' />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className='min-w-[200px] bg-surface-02 p-2 rounded-md shadow-lg text-sm text-primary z-30'
          sideOffset={5}
          align='end'
          side='top'
        >
          {localSupportLinks.map((supportLink, index) => (
            <React.Fragment key={supportLink.url}>
              <DropdownMenu.Item className='flex items-center gap-4 rounded-md cursor-pointer hover:bg-surface-03 focus:outline-none'>
                <a href={supportLink.url} target='_blank' className='p-2 w-full' rel='noopener noreferrer'>
                  <div className='flex items-center grow'>
                    <div className='flex flex-col'>{supportLink.title} </div>
                  </div>
                </a>
              </DropdownMenu.Item>
              {index !== localSupportLinks.length - 1 && (
                <DropdownMenu.Separator className='my-3 mx-2 h-px bg-surface-03' />
              )}
            </React.Fragment>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default memo(HelpButton);
