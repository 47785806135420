import {MobileHeader} from '#/components/MobileHeader';
import Page from '#/components/Page';
import {AssistantCard} from '#/components/assistants/AssistantsPage';
import {useAssistants} from '#/hooks/use-assistants.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {ReactComponent as CrossCircledIcon} from '#/resources/cross-circled-icon.svg';
import {ReactComponent as MagnifierIcon} from '#/resources/magnifier-icon.svg';
import {ReactComponent as PlusIcon} from '#/resources/plus-icon.svg';
import {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import {twMerge} from 'tailwind-merge';

const AssistantsPageSection: FunctionComponent<{
  assistants: AssistantPublicResponse[];
}> = props => {
  return (
    <div className='@container/assistant-page-section'>
      <div className='mt-4 grid grid-cols-1 auto-rows-min gap-4 @lg/assistant-page-section:grid-cols-3 @3xl/assistant-page-section:grid-cols-4'>
        {props.assistants.map(assistant => (
          <AssistantCard key={assistant.id} assistant={assistant} />
        ))}
      </div>
    </div>
  );
};

interface SearchFieldProps {
  searchField: string;
  setSearchField: (value: string) => void;
  className?: string;
}

const SearchField: FunctionComponent<SearchFieldProps> = ({searchField, setSearchField, className}) => {
  const {t} = useTranslation();

  return (
    <div className={twMerge('relative flex', className)}>
      <TextareaAutosize
        className='grow text-primary focus:outline-none focus:bg-surface-02 p-3 px-14 rounded-2xl border border-stroke-main resize-none bg-surface-01 text-lg w-80'
        value={searchField}
        onChange={e => setSearchField(e.target.value)}
        placeholder={t('all-assistants.search.placeholder')}
        maxRows={1}
        autoFocus
      />

      <MagnifierIcon className='absolute top-1/2 left-6 -translate-y-1/2' />

      {searchField.length > 0 && (
        <button
          onClick={() => setSearchField('')}
          className='absolute top-1/2 right-6 -translate-y-1/2 transition-opacity opacity-100'
        >
          <CrossCircledIcon className='stroke-gray-500' />
        </button>
      )}
    </div>
  );
};

const AllAssistantsPage: FunctionComponent = () => {
  const {assistants} = useAssistants();
  const {t} = useTranslation();

  const [searchField, setSearchField] = useState('');

  const filteredAssistants = assistants.filter(assistant =>
    assistant.name.toLowerCase().includes(searchField.toLowerCase()),
  );

  return (
    <Page title={t('all-assistants.page-header.title')}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>{t('all-assistants.page-header.title')} </h2>

        <NavLink
          to='/assistants/create'
          className='aspect-square h-12 rounded-md bg-accent text-accent-inverse flex justify-center items-center hover:opacity-70 transition-opacity'
        >
          <PlusIcon className='size-7 stroke-accent-inverse' />
        </NavLink>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch pb-3 md:pb-6 overflow-y-auto'>
        <PageContentHeader title={t('all-assistants.page-header.title')}>
          <SearchField searchField={searchField} setSearchField={setSearchField} />
        </PageContentHeader>

        <div className='max-w-page-content grow w-full flex flex-col mx-auto p-4'>
          <SearchField searchField={searchField} setSearchField={setSearchField} className='md:hidden' />

          {!!filteredAssistants.length ? (
            <AssistantsPageSection assistants={filteredAssistants} />
          ) : (
            <div className='md:mt-4'>{t('all-assistants.search.no-results')}</div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default AllAssistantsPage;
