import {CurrentUserAvatar} from '#/components/UserAvatar';
import {useAuth} from '#/hooks/use-auth';
import {ExitIcon, GearIcon} from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

const ProfileButton = () => {
  const {t} = useTranslation();
  const {user} = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const LinkToAdminPage = () => {
    return user?.isAdmin ? (
      <NavLink
        to='/admin'
        className='flex items-center gap-3 p-2 text-sm rounded-md cursor-pointer hover:bg-surface-03 outline-none'
      >
        <GearIcon width={18} height={18} />
        <p className='text-sm'>{t('sidebar.actions.admin')}</p>
      </NavLink>
    ) : null;
  };

  return (
    <Popover.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
      <Popover.Trigger asChild onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <button className='size-6 p-0.5 shrink-0 rounded-xl flex justify-center items-center hover:opacity-70 transition-opacity'>
          <CurrentUserAvatar className='size-full text-[0.6rem]' />
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className='rounded p-1.5 shadow-lg bg-surface-02 outline-none z-30 text-primary'
          sideOffset={5}
          align='end'
          side='top'
          onClick={() => setIsMenuOpen(false)}
        >
          <LinkToAdminPage />

          <NavLink
            to='/logout'
            className='flex items-center gap-3 p-2 text-sm rounded-md cursor-pointer hover:bg-surface-03 outline-none'
          >
            <ExitIcon width={18} height={18} />
            <p className='text-sm'>{t('sidebar.actions.logout')}</p>
          </NavLink>
          <NavLink
            to='/settings'
            className='flex items-center gap-3 p-2 text-sm rounded-md cursor-pointer hover:bg-surface-03 outline-none'
          >
            <GearIcon width={18} height={18} />
            <p className='text-sm'>{t('sidebar.actions.settings')}</p>
          </NavLink>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default ProfileButton;
