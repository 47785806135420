import {useAllAssistantsQuery} from '#/hooks/query/assistants';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import React, {createContext, ReactNode, useContext, useMemo} from 'react';

interface AssistantsContextType {
  assistants: AssistantPublicResponse[];
}

const AssistantsContext = createContext<AssistantsContextType | undefined>(undefined);

export const useAssistants = () => {
  const context = useContext(AssistantsContext);
  if (context === undefined) {
    throw new Error('useAssistants must be used within a AssistantsProvider');
  }
  return context;
};

export const useUserVisibleAssistants = (assistants: AssistantPublicResponse[]) => {
  return useMemo(() => {
    return assistants.reduce(
      (acc, assistant) => {
        if (assistant.is_owner) {
          acc.privateAssistants.push(assistant);
        } else if (assistant.is_collaborator) {
          acc.collaborativeAssistants.push(assistant);
        } else {
          acc.sharedAssistants.push(assistant);
        }
        return acc;
      },
      {
        privateAssistants: [] as AssistantPublicResponse[],
        collaborativeAssistants: [] as AssistantPublicResponse[],
        sharedAssistants: [] as AssistantPublicResponse[],
      },
    );
  }, [assistants]);
};

export const AssistantsProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const assistantsQuery = useAllAssistantsQuery();

  return (
    <AssistantsContext.Provider
      value={{
        assistants: assistantsQuery.data || [],
      }}
    >
      {children}
    </AssistantsContext.Provider>
  );
};

export const NoAssistantsProvider: React.FC<{children: ReactNode}> = ({children}) => {
  return (
    <AssistantsContext.Provider
      value={{
        assistants: [],
      }}
    >
      {children}
    </AssistantsContext.Provider>
  );
};
