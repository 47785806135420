import {default as LegacyButton} from '#/components/Button';
import {ArrowDownIcon} from '@radix-ui/react-icons';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useScrollToBottom, useSticky} from 'react-scroll-to-bottom';

export const ScrollToBottomButton = () => {
  const {t} = useTranslation();

  const scrollToBottom = useScrollToBottom();
  const [isSticky] = useSticky();

  const onClick = useCallback(() => {
    scrollToBottom({behavior: 'smooth'});
  }, [scrollToBottom]);

  return (
    !isSticky && (
      <LegacyButton
        className='h-10 aspect-square absolute bottom-[120px] flex justify-center items-center left-1/2 -translate-x-1/2 rounded-lg z-10 border border-stroke-main bg-surface-03 shadow-xl hover:shadow-none'
        title={t('conversation.actions.scroll-to-bottom.hint')}
        onClick={onClick}
      >
        <ArrowDownIcon className='text-primary stroke-primary stroke-[0.5px] inline size-4 scale-x-[110%]' />
      </LegacyButton>
    )
  );
};
