import {prependUpdatedConversation, useConversationsSetQueryData} from '#/hooks/query/conversations.tsx';
import {useConversations} from '#/hooks/use-conversations.tsx';
import {ConversationResponse, fetchConversation} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {castToConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations.ts';
import {useCallback} from 'react';

export const usePrependUpdatedConversationIfNeeded = (currentConversation: ConversationResponse | undefined) => {
  const {conversations} = useConversations();
  const setConversationsQueryData = useConversationsSetQueryData();

  return useCallback(async () => {
    if (!currentConversation || currentConversation.id === conversations[0]?.id) {
      return;
    }

    const response = await fetchConversation(currentConversation.id);
    const {data: updatedConversation} = response;

    if (!updatedConversation) return;

    setConversationsQueryData(oldData => {
      return prependUpdatedConversation(oldData, castToConversationSummaryResponse(updatedConversation));
    });
  }, [currentConversation, conversations, setConversationsQueryData]);
};
