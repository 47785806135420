import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {AxiosResponse} from 'axios';

export type UploadAssistantAvatarRequest = {
  assistant_id: string;
  file: File;
};

export type UploadAssistantAvatarResponse = {
  protected_url: string;
};

export const uploadAssistantAvatar = (
  request: UploadAssistantAvatarRequest,
): Promise<AxiosResponse<UploadAssistantAvatarResponse>> => {
  const formData = new FormData();
  formData.append('file', request.file);
  return ScoutAPI.post<UploadAssistantAvatarRequest, AxiosResponse<UploadAssistantAvatarResponse>>(
    `/assistants/${request.assistant_id}/avatar/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};
