import ScoutAPI from '#/repositories/assistants-api/api';
import {AxiosResponse} from 'axios';

export interface SignedUrlResponse {
  url: string;
}

export const fetchSignedUrl = (url: string): Promise<AxiosResponse<SignedUrlResponse>> => {
  return ScoutAPI.get<null, AxiosResponse<SignedUrlResponse>>(url);
};
