import {useUpdateConversationMutation} from '#/hooks/query/conversations.tsx';
import {ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {QueryObserverResult} from '@tanstack/react-query';
import {useCallback} from 'react';

const useSetSelectedModelId = (
  conversationId: string | undefined,
  refetchConversationQuery: () => Promise<QueryObserverResult<ConversationResponse, Error>>,
  setDefaultModelId: (value: React.SetStateAction<string | undefined>) => void,
) => {
  const {mutateAsync: updateConversationMutateAsync} = useUpdateConversationMutation();

  return useCallback(
    async (selectedModelId: string | undefined) => {
      setDefaultModelId(selectedModelId);

      if (conversationId) {
        await updateConversationMutateAsync({
          conversationId: conversationId,
          editedConversation: {model: selectedModelId},
        });
        refetchConversationQuery();
      }
    },
    [conversationId, refetchConversationQuery, setDefaultModelId, updateConversationMutateAsync],
  );
};

export default useSetSelectedModelId;
