import {MustUseWithinContextProviderError} from '#/hooks/MustUseWithinContextProviderError';
import {LocalStorageKeys} from '#/repositories/environment.ts';
import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useLocalStorage, useToggle} from 'react-use';

interface SidebarContextType {
  isMobileVisible: boolean;
  mobileToggle: () => void;
  isDesktopCollapsed: boolean | undefined;
  desktopToggle: () => void;
  shouldPreventDismissMobile: boolean;
  setShouldPreventDismissMobile: (value: boolean) => void;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [isMobileVisible, mobileToggle] = useToggle(false);
  const [shouldPreventDismissMobile, setShouldPreventDismissMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isMobileVisible && !shouldPreventDismissMobile) {
      mobileToggle(false);
    } else if (isMobileVisible && shouldPreventDismissMobile) {
      setShouldPreventDismissMobile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, mobileToggle]);

  const [isDesktopCollapsed, setIsDesktopCollapsed] = useLocalStorage(LocalStorageKeys.SIDEBAR_COLLAPSED, false);
  const desktopToggle = useCallback(() => {
    setIsDesktopCollapsed(!isDesktopCollapsed);
  }, [isDesktopCollapsed, setIsDesktopCollapsed]);

  return (
    <SidebarContext.Provider
      value={{
        isMobileVisible,
        mobileToggle,
        isDesktopCollapsed,
        desktopToggle,
        shouldPreventDismissMobile,
        setShouldPreventDismissMobile,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);

  if (context === undefined) {
    throw new MustUseWithinContextProviderError('useSidebar', 'SidebarProvider');
  }
  return context;
};
