import AssistantPresentation from '#/components/chat-page/new-conversation/AssistantPresentation.tsx';
import PlainNewConversationView from '#/components/chat-page/new-conversation/PlainNewConversationView.tsx';
import UseCasesView from '#/components/chat-page/new-conversation/UseCasesView';
import {useAppSettings} from '#/hooks/use-app-settings';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {FunctionComponent, memo} from 'react';

export interface ConversationComponentProps {
  assistant?: AssistantPublicResponse;
  assistantIsLoading: boolean;
  onStarterPrompt: (starter: string) => void;
  maxWidth?: string;
}

const NewConversationPresentation: FunctionComponent<ConversationComponentProps> = ({
  assistant,
  maxWidth,
  assistantIsLoading,
  onStarterPrompt,
}) => {
  const {useCasesViewEnabled} = useAppSettings();

  return (
    <div className='size-full'>
      {assistantIsLoading || assistant ? (
        <AssistantPresentation
          assistant={assistant}
          assistantIsLoading={assistantIsLoading}
          onStarterPrompt={onStarterPrompt}
          maxWidth={maxWidth}
        />
      ) : useCasesViewEnabled ? (
        <UseCasesView maxWidth={maxWidth} />
      ) : (
        <PlainNewConversationView maxWidth={maxWidth} />
      )}
    </div>
  );
};

export default memo(NewConversationPresentation);
