import FunctionPermissionAlert, {
  OnRespondFunctionPermissionFn,
} from '#/components/chat-page/function-permissions/FunctionPermissionAlert.tsx';
import ToolStatusLoader from '#/components/tool-loaders/ToolStatusLoader.tsx';
import {useErrorMessage} from '#/hooks/use-error-message.tsx';
import Alert from '#/library/alert/Alert.tsx';
import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {
  StreamChunk,
  convertStreamChunkToConversationMessage,
  streamChunkIsRelatedToToolCallId,
} from '#/utils/streaming-utils.ts';
import {Dispatch, SetStateAction, useCallback} from 'react';

export const useSetMessagesOnStreamChunks = (setMessages: Dispatch<SetStateAction<ConversationMessage[]>>) => {
  const {convertErrorMessageToUserMessage} = useErrorMessage();

  return useCallback(
    (
      streamChunks: StreamChunk[],
      conversation: ConversationMessage[],
      onRespondFunctionPermission: OnRespondFunctionPermissionFn,
    ) => {
      const newConv = [...conversation];

      streamChunks.forEach(streamChunk => {
        const statusComponents =
          streamChunk.tool_calls?.reduce((acc, tool_call) => {
            if (streamChunkIsRelatedToToolCallId(streamChunks.at(-1), tool_call.call_id)) {
              return [
                ...acc,
                <ToolStatusLoader
                  toolName={tool_call.tool_name}
                  status={tool_call.status}
                  arguments={tool_call.arguments}
                />,
              ];
            }
            return acc;
          }, [] as JSX.Element[]) || [];

        if (streamChunk.finish_reason === 'REQUIRES_CONFIRMATION' && streamChunk.tool_calls?.length) {
          statusComponents.push(
            <FunctionPermissionAlert
              toolCall={streamChunk.tool_calls[0]}
              onRespond={onRespondFunctionPermission}
              conversation={newConv}
            />,
          );
        }

        if (streamChunk.error !== undefined) {
          // eslint-disable-next-line no-console
          console.error(streamChunk.error);
          statusComponents.push(
            <Alert variant='warning'>{convertErrorMessageToUserMessage(streamChunk.error.message)}</Alert>,
          );
        }

        if (streamChunk.role !== undefined) {
          newConv.push(convertStreamChunkToConversationMessage(streamChunk));
        }
        if (statusComponents.length > 0) {
          newConv.push({role: 'assistant', content: <div>{...statusComponents}</div>, metadata: streamChunk.metadata});
        }
      });

      setMessages(newConv);
    },
    [convertErrorMessageToUserMessage, setMessages],
  );
};
