import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {AxiosResponse} from 'axios';

export type UserCustomPromptResponse = {
  prompt: string;
};

export const fetchUserCustomPrompt = (): Promise<AxiosResponse<UserCustomPromptResponse>> => {
  return ScoutAPI.get<UserCustomPromptResponse>('/users/custom-prompt');
};
