import {CurrentUserAvatar} from '#/components/UserAvatar';
import {useAuth} from '#/hooks/use-auth';
import * as Collapsible from '@radix-ui/react-collapsible';
import {CaretDownIcon, ExitIcon, GearIcon} from '@radix-ui/react-icons';
import {ComponentProps, FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {useLocalStorage} from 'react-use';

const ProfileToggle = () => {
  const {t} = useTranslation();

  const {user} = useAuth();

  const [open, setOpen] = useLocalStorage('sidebar-profile-open', false);

  return (
    <Collapsible.Root
      className='rounded-2xl bg-surface-02 py-5 space-y-5 w-full text-primary mt-auto'
      open={open}
      onOpenChange={setOpen}
    >
      <div className='flex h-9 gap-1 items-center px-4'>
        <CurrentUserAvatar className='h-full aspect-square text-sm' />

        <div className='grow text-base font-semibold pl-2'>
          <span>{`${user?.firstName} ${user?.lastName}`}</span>
        </div>

        <Collapsible.Trigger className='h-full aspect-square rounded-lg bg-surface-03 flex justify-center items-center hover:opacity-70 transition-opacity'>
          <CaretDownIcon className='size-6 data-[open=false]:-scale-y-100' data-open={open} />
        </Collapsible.Trigger>
      </div>

      <Collapsible.Content>
        <ProfileToggleListItem
          text={t('sidebar.actions.settings')}
          to='/settings'
          icon={<GearIcon className='size-4' />}
        />
        <ProfileToggleListItem text={t('sidebar.actions.logout')} to='/logout' icon={<ExitIcon className='size-4' />} />
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

interface ProfileToggleListItemProps extends ComponentProps<typeof NavLink> {
  icon?: React.ReactElement;
  text: string;
}

const ProfileToggleListItem: FunctionComponent<ProfileToggleListItemProps> = ({
  className = '',
  children,
  icon,
  text,
  ...props
}) => {
  return (
    <NavLink
      {...props}
      className='flex gap-2 justify-between items-center rounded-lg text-primary font-semibold hover:bg-surface-03 cursor-pointer mx-2 px-4 py-2.5 data-[dropdownopen=true]:bg-surface-03'
    >
      <span>{text}</span>
      {icon}
    </NavLink>
  );
};

export default ProfileToggle;
