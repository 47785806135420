import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {ApiTokensTokenResponse} from '#/repositories/assistants-api/requests/fetch-api-tokens.ts';
import {AxiosResponse} from 'axios';

export interface UpdateApiTokenRequest {
  description?: string;
}

export const updateApiToken = async (tokenId: string, request: UpdateApiTokenRequest) => {
  return await ScoutAPI.put<UpdateApiTokenRequest, AxiosResponse<ApiTokensTokenResponse>>(
    `/auth/api-tokens/${tokenId}/`,
    request,
  );
};
