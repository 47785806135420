import {MobileHeader} from '#/components/MobileHeader.tsx';
import Page from '#/components/Page.tsx';
import {useUpdateUserCustomPromptMutation, useUserCustomPromptQuery} from '#/hooks/query/user-custom-prompt.tsx';
import {Button} from '#/library/button/Button.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import {Textarea} from '#/library/textarea/Textarea.tsx';
import {ReactComponent as CheckmarkIcon} from '#/resources/checkmark-icon.svg';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const UserCustomPromptPage: FunctionComponent = () => {
  const {t} = useTranslation();
  const [customPrompt, setCustomPrompt] = useState('');

  const userCustomPromptQuery = useUserCustomPromptQuery();
  useEffect(() => {
    if (userCustomPromptQuery.data) {
      setCustomPrompt(userCustomPromptQuery.data.prompt);
    }
  }, [userCustomPromptQuery.data]);

  const navigate = useNavigate();
  const updateUserCustomPromptMutation = useUpdateUserCustomPromptMutation();
  const handleSaveCustomPrompt = useCallback(async () => {
    await updateUserCustomPromptMutation.mutateAsync({prompt: customPrompt});
    navigate('/chat');
  }, [customPrompt, navigate, updateUserCustomPromptMutation]);

  return (
    <Page title={t('settings.page-header.title')}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>
          {t('settings.preferences.user-custom-prompt.label')}
        </h2>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch overflow-y-auto'>
        <PageContentHeader title={t('settings.preferences.user-custom-prompt.label')} />

        <div className='max-w-page-content grow w-full flex flex-col mx-auto gap-y-8 p-4 md:pt-0'>
          <p>{t('settings.preferences.user-custom-prompt.description')}</p>

          <Textarea
            id='assistant-instructions'
            value={customPrompt}
            onValueChange={e => setCustomPrompt(e.target.value)}
            minRows={10}
          />

          <Button
            variant='primary'
            size='sm'
            textSize='base'
            onClick={handleSaveCustomPrompt}
            className='w-fit self-end'
            isLoading={updateUserCustomPromptMutation.isPending}
          >
            <CheckmarkIcon className='stroke-accent-inverse' />
            {t('settings.preferences.user-custom-prompt.actions.save')}
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default UserCustomPromptPage;
