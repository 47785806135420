import {ChatModel, ModelTag} from '#/repositories/config.ts';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {QuestionMarkCircledIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

export interface ModelSelectFieldItemProps {
  chatModel: ChatModel;
}

export const ModelSelectFieldItem: FunctionComponent<ModelSelectFieldItemProps> = ({chatModel}) => {
  const [displayDescription, setDisplayDescription] = useState(false);

  const tooltipButtonRef = useRef<HTMLDivElement>(null);

  const handleMobileTooltipPress = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setDisplayDescription(!displayDescription);
  };

  const handleMobileOutsidePress = useCallback(
    (event: MouseEvent) => {
      if (displayDescription && !tooltipButtonRef.current?.contains(event.target as Node)) setDisplayDescription(false);
    },
    [displayDescription],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMobileOutsidePress);

    return () => {
      document.removeEventListener('mousedown', handleMobileOutsidePress);
    };
  }, [handleMobileOutsidePress]);

  return (
    <DropdownMenu.RadioItem
      value={chatModel.id}
      className='has-tooltip flex items-center gap-4 p-2 rounded-md cursor-pointer group/model-select-item hover:bg-surface-03 transition-colors focus:outline-none'
    >
      <div className='tooltip text-base text-primary w-[250px] left-[105%] rounded-md bg-surface-02 border border-stroke-main p-4'>
        {chatModel.description}
      </div>
      <div className='flex items-center grow w-[240px]'>
        <div className='flex gap-1 flex-row items-center has-mobile-tooltip'>
          <div className='flex flex-col gap-2'>
            <div className='font-semibold'>{chatModel.displayName}</div>
            {chatModel.tags?.length && (
              <div className='flex flex-wrap gap-2'>
                {chatModel.tags?.map((tag, index) => <ModelTagView tag={tag} key={tag} />)}
              </div>
            )}
          </div>
          {displayDescription && (
            <div className='tooltip select-none text-base text-white w-[200px] -mb-2 rounded-md bg-surface-02 border border-stroke-main p-4'>
              {chatModel.description}
            </div>
          )}
        </div>
      </div>
      <div ref={tooltipButtonRef} onClick={handleMobileTooltipPress} className='md:hidden w-[24px]'>
        <QuestionMarkCircledIcon />
      </div>
      <div className='relative size-6 shrink-0 border-secondary border-2 rounded-full overflow-hidden p-1'>
        <DropdownMenu.ItemIndicator>
          <div className='bg-accent size-full rounded-full overflow-hidden' />
        </DropdownMenu.ItemIndicator>
      </div>
    </DropdownMenu.RadioItem>
  );
};

export const ModelTagView: FunctionComponent<{tag: ModelTag}> = ({tag}) => {
  const {t} = useTranslation();

  return (
    <span className='text-secondary font-bold text-2xs uppercase px-2 py-1 bg-surface-03 rounded-2xl group-hover/model-select-item:bg-surface-02'>
      {t(`conversation.chat-models.tags.${tag}`)}
    </span>
  );
};
