import AssistantsAPI from '#/repositories/assistants-api/api.ts';
import {AxiosResponse} from 'axios';

export interface ConnectedServiceResponse {
  name: string;
}

export interface ExternalServicesResponse {
  services: ConnectedServiceResponse[];
}

export const fetchUserConnectedServices = (): Promise<AxiosResponse<ExternalServicesResponse>> => {
  return AssistantsAPI.get<ExternalServicesResponse>('/users/connected-services');
};
