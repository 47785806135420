import {ToolUsedItemProps, ToolUsedItemWrapper} from '#/components/chat-page/tools-used/ToolUsedItem.tsx';
import {castJson} from '#/utils/json-utils.ts';
import {ImageIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface GenerateImageContentToolArguments {
  prompt: string;
}

const GenerateImageContentToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<GenerateImageContentToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <ImageIcon className='inline-block size-4 text-accent shrink-0' />
        <span>
          {t('conversation.tool-used.tools.generate-image', {
            prompt: toolArguments?.prompt,
          })}
        </span>
      </div>
    </ToolUsedItemWrapper>
  );
};

export default GenerateImageContentToolUsedItem;
