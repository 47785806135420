import ScoutAPI from '#/repositories/assistants-api/api';
import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation';
import {AxiosResponse} from 'axios';

export type ChatCompletionResponse = {
  messages: ConversationMessage[];
  error?: {
    code: number;
    message: string;
  };
};

export type ChatCompletionRequest = {
  messages: ConversationMessage[];
  model: string;
};

export const chatCompletion = (request: ChatCompletionRequest): Promise<AxiosResponse<ChatCompletionResponse>> => {
  return ScoutAPI.post<ChatCompletionRequest, AxiosResponse<ChatCompletionResponse>>('/chat/completion/', request);
};
