import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {CreateConversationRequest} from '#/repositories/assistants-api/requests/create-conversation.ts';
import {AssistantResponse} from '#/repositories/assistants-api/requests/fetch-assistant.ts';
import {AxiosResponse} from 'axios';

export const revokeApiToken = async (tokenId: string) => {
  return await ScoutAPI.post<CreateConversationRequest, AxiosResponse<AssistantResponse>>(
    `/auth/api-tokens/${tokenId}/revoke/`,
  );
};
