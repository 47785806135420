import ModelSelectField from '#/components/ModelSelectField.tsx';
import Page from '#/components/Page.tsx';
import ConversationComponent from '#/components/chat-page/ConversationComponent.tsx';
import {MENTION_ASSISTANT_REGEX} from '#/components/chat-page/MentionAssistantSelect.tsx';
import {OnEditSubmitType} from '#/hooks/chat-page/use-edit-message.tsx';
import useFocusPromptInputOnLocationChange from '#/hooks/chat-page/use-focus-prompt-input-on-location-change.tsx';
import {usePrependUpdatedConversationIfNeeded} from '#/hooks/chat-page/use-prepend-updated-conversation-if-needed.tsx';
import {usePromptInputDataToShow} from '#/hooks/chat-page/use-prompt-input-data-to-show.tsx';
import {useRedirectOnInvalidConversation} from '#/hooks/chat-page/use-redirect-on-invalid-conversation.tsx';
import {useSendMessageFromBrowserExtension} from '#/hooks/chat-page/use-send-message-from-browser-extension.tsx';
import useSendMessageOnQuerySearchParam from '#/hooks/chat-page/use-send-message-on-query-search-param.tsx';
import {useSendMessagesOnNavigateWithNewMessages} from '#/hooks/chat-page/use-send-messages-on-navigate-with-new-messages.tsx';
import {useSetFormStateOnConversation} from '#/hooks/chat-page/use-set-form-state-on-conversation.tsx';
import useSetSelectedModelId from '#/hooks/chat-page/use-set-selected-model-id.tsx';
import {useSetSelectedModelWhenNotRecognized} from '#/hooks/chat-page/use-set-selected-model-when-not-recognized.tsx';
import {useStartStreamingOnNewConversation} from '#/hooks/chat-page/use-start-streaming-on-new-conversation.tsx';
import {usePublicAssistantQuery} from '#/hooks/query/assistants.tsx';
import {useChatModelsQuery} from '#/hooks/query/chat-models.tsx';
import {useConversationQuery, useCreateConversationMutation} from '#/hooks/query/conversations.tsx';
import {useDefaultModelId} from '#/hooks/use-default-model-id.tsx';
import {useErrorMessage} from '#/hooks/use-error-message.tsx';
import {useSetMessagesOnStreamChunks} from '#/hooks/use-set-messages-on-stream-chunks.tsx';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation';
import {
  FunctionPermission,
  StreamChatCompletionRequest,
  streamChatCompletion,
} from '#/repositories/assistants-api/requests/stream-chat-completion.ts';
import {ReactComponent as GearIcon} from '#/resources/gear-icon.svg';
import {getTimeZoneOffset} from '#/utils/time-utils.ts';
import {PlusIcon} from '@radix-ui/react-icons';
import {FormEvent, FunctionComponent, memo, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Location, NavLink, useLocation, useNavigate, useParams} from 'react-router-dom';

export type Sender = 'user' | 'assistant' | 'system' | 'tool';

type ChatPageParams = {
  conversationId: string;
  assistantId: string;
};

export type ChatPageState = {
  shouldStartStream: boolean;
  newMessages?: ConversationMessage[];
} | null;

export const ChatPage: FunctionComponent = () => {
  const {t} = useTranslation();

  const {conversationId, assistantId} = useParams<ChatPageParams>();
  const navigate = useNavigate();
  const location: Location<ChatPageState> = useLocation();

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<ConversationMessage[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const abortControllerRef = useRef<AbortController>();

  const promptInputRef = useRef<HTMLTextAreaElement>(null);
  useFocusPromptInputOnLocationChange(promptInputRef, location);

  const createConversationMutation = useCreateConversationMutation();
  const loading = createConversationMutation.isPending || isLoading;

  const conversationQuery = useConversationQuery({conversationId});
  const conversationIsLoading = conversationQuery.isLoading;
  useRedirectOnInvalidConversation(conversationQuery);
  useSetFormStateOnConversation(conversationQuery, setMessages, setIsLoading, setError, abortControllerRef);

  const {defaultModelId, setDefaultModelId} = useDefaultModelId();
  const chatModels = useChatModelsQuery();
  const selectedModelId = conversationQuery.data?.model || defaultModelId;

  const setSelectedModelId = useSetSelectedModelId(conversationId, conversationQuery.refetch, setDefaultModelId);
  useSetSelectedModelWhenNotRecognized(chatModels, selectedModelId, setSelectedModelId);

  const prependUpdatedConversationIfNeeded = usePrependUpdatedConversationIfNeeded(conversationQuery.data);

  const publicAssistantQuery = usePublicAssistantQuery({assistantId});
  const assistant = conversationQuery.data?.assistant || publicAssistantQuery.data;
  const assistantIsLoading = publicAssistantQuery.isLoading;

  const [mentionedAssistant, setMentionedAssistant] = useState<AssistantPublicResponse | null>(null);

  const handleMentionedAssistantSelect = useCallback(
    (assistant: AssistantPublicResponse | null) => {
      setMentionedAssistant(assistant);
      setMessage(message.replace(MENTION_ASSISTANT_REGEX, ''));
    },
    [message],
  );

  const handleRemoveMentionedAssistant = useCallback(() => {
    setMentionedAssistant(null);
  }, []);

  const {messageToShow, filesToShow, mentionedAssistantToShow, setSendMessageIsPending} = usePromptInputDataToShow(
    createConversationMutation.isError,
    message,
    files,

    mentionedAssistant,
  );

  const handleStopStreaming = useCallback(() => {
    setIsLoading(false);
    if (location.state?.shouldStartStream) {
      navigate('/chat/' + conversationId, {replace: true});
    }
  }, [navigate, location.state?.shouldStartStream, conversationId]);

  const setMessagesOnStreamChunks = useSetMessagesOnStreamChunks(setMessages);

  const {convertErrorMessageToUserMessage} = useErrorMessage();

  const handleStreamChatCompletion = useCallback(
    async (
      newMessages: ConversationMessage[] | null,
      conversation: ConversationMessage[],
      functionPermissions?: FunctionPermission[],
    ) => {
      setIsLoading(true);
      setSendMessageIsPending(true);

      const request: StreamChatCompletionRequest = {
        new_messages: newMessages,
        model: selectedModelId || chatModels[0]?.id,
        mentioned_assistant_id: mentionedAssistant?.id,
        function_permissions: functionPermissions,
      };

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      try {
        await streamChatCompletion(
          conversationId || '',
          request,
          chunks =>
            setMessagesOnStreamChunks(chunks, conversation, (functionPermissions, conversation) =>
              handleStreamChatCompletion(null, conversation, functionPermissions),
            ),
          files,
          error => {
            if (!abortControllerRef.current?.signal.aborted) {
              setError(convertErrorMessageToUserMessage(error));
              setIsLoading(false);
            }
            throw error;
          },
          () => {
            handleStopStreaming();
          },
          abortController.signal,
        );
      } finally {
        setSendMessageIsPending(false);
      }
      prependUpdatedConversationIfNeeded();
      setMessage('');
      setFiles([]);
      setMentionedAssistant(null);
    },
    [
      setSendMessageIsPending,
      selectedModelId,
      chatModels,
      mentionedAssistant?.id,
      prependUpdatedConversationIfNeeded,
      conversationId,
      files,
      setMessagesOnStreamChunks,
      convertErrorMessageToUserMessage,
      handleStopStreaming,
    ],
  );

  useStartStreamingOnNewConversation(messages, handleStreamChatCompletion, location.state, conversationQuery);

  const sendMessage = useCallback(
    async (newMessages: ConversationMessage[], title: string | undefined = undefined) => {
      const newConversation = [...messages, ...newMessages];

      setError('');
      if (conversationId === undefined) {
        createConversationMutation.mutate({
          title,
          time_zone_offset: getTimeZoneOffset(),
          payload: newConversation,
          assistant_id: assistantId,
          model: selectedModelId || '',
        });
        return;
      }

      handleStreamChatCompletion(newMessages, newConversation);
      setMessages(newConversation);
    },
    [messages, conversationId, handleStreamChatCompletion, createConversationMutation, assistantId, selectedModelId],
  );

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isLoading) {
        abortControllerRef.current?.abort();
        handleStopStreaming();
        return;
      }

      setIsLoading(true);
      setSendMessageIsPending(true);

      if (!selectedModelId) {
        return;
      }

      const newMessage: ConversationMessage = {
        role: 'user' as Sender,
        content: message,
        metadata: {
          files: files.map(file => ({name: file.name, content_type: file.type})),
        },
      };

      await sendMessage([newMessage]);
    },
    [files, handleStopStreaming, isLoading, message, selectedModelId, sendMessage, setSendMessageIsPending],
  );

  useSendMessageFromBrowserExtension(sendMessage);

  useSendMessagesOnNavigateWithNewMessages(location.state, sendMessage, conversationQuery);

  useSendMessageOnQuerySearchParam(sendMessage, conversationId);

  const handleEditSubmit: OnEditSubmitType = useCallback(
    updatedConversation => {
      if (!updatedConversation) {
        return;
      }
      const updatedMessages = [...updatedConversation.payload];
      setMessages(updatedMessages);
      handleStreamChatCompletion(null, updatedMessages);
    },
    [handleStreamChatCompletion],
  );

  return (
    <Page title={t('conversation.page-title')}>
      <ConversationComponent
        conversationId={conversationId}
        messages={messages}
        conversationIsLoading={conversationIsLoading}
        isLoading={loading}
        message={messageToShow}
        onMessageChange={setMessage}
        files={filesToShow}
        onFileChange={setFiles}
        onSubmit={onSubmit}
        error={error}
        assistant={assistant}
        assistantIsLoading={assistantIsLoading}
        mentionedAssistant={mentionedAssistantToShow}
        onMentionedAssistantSelect={handleMentionedAssistantSelect}
        onRemoveMentionedAssistant={handleRemoveMentionedAssistant}
        promptInputRef={promptInputRef}
        onEditSubmit={handleEditSubmit}
        ModelSelectComponent={
          <ModelSelectField
            chatModels={chatModels}
            selectedModelId={selectedModelId}
            setSelectedModelId={setSelectedModelId}
            className='absolute top-0 left-0 z-40 m-5 md:flex hidden'
          />
        }
        MobileModelSelectComponent={
          <ModelSelectField
            chatModels={chatModels}
            selectedModelId={selectedModelId}
            setSelectedModelId={setSelectedModelId}
            className='md:hidden'
          />
        }
        AdditionalComponent={<AssistantButtons assistant={assistant} />}
      />
    </Page>
  );
};

const AssistantButtons: FunctionComponent<{assistant?: AssistantPublicResponse}> = memo(({assistant}) => {
  const {t} = useTranslation();

  return (
    <div className='absolute top-4 right-4 flex gap-3'>
      {assistant && (assistant.is_owner || assistant.is_collaborator) && (
        <NavLink
          to={`/assistants/${assistant.id}/edit`}
          className='flex gap-2 h-12 rounded-lg bg-surface-02 p-4 items-center z-10 hover:opacity-70 transition-opacity group'
        >
          <GearIcon className='group-hover:rotate-[30deg] transition stroke-primary' />
          <span className='font-bold'>{t('conversation.assistant.edit-button')}</span>
        </NavLink>
      )}

      {assistant && (
        <NavLink
          to={`/assistants/${assistant.id}/chat`}
          className='flex gap-2 h-12 rounded-lg bg-surface-02 p-4 items-center z-10 hover:opacity-70 transition-opacity group'
        >
          <PlusIcon className='size-7 stroke-primary' />
        </NavLink>
      )}
    </div>
  );
});
