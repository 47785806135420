import ScoutAPI from '#/repositories/assistants-api/api';
import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {fetchWithTokenRefresh} from '#/utils/request-utils.ts';
import {handleChatStreamResponse, StreamChatCompletionOnMessageEvent} from '#/utils/streaming-utils.ts';

export type FunctionPermission = {call_id?: string; function_name?: string; denied?: boolean};

export type StreamChatCompletionRequest = {
  new_messages: ConversationMessage[] | null;
  model: string;
  mentioned_assistant_id?: string;
  function_permissions?: FunctionPermission[];
};

export const streamChatCompletion = async (
  conversationId: string,
  request: StreamChatCompletionRequest,
  onMessage: StreamChatCompletionOnMessageEvent,
  files: File[],
  onError: (error: string) => void,
  onStreamEnd: () => void,
  signal: AbortSignal,
) => {
  const formData = new FormData();
  files?.length && files.forEach(file => formData.append(file.name, file));
  formData.append('chat_request', JSON.stringify(request));

  try {
    const response = await fetchWithTokenRefresh(
      `${ScoutAPI.defaults.baseURL}/conversations/${conversationId}/completion/`,
      {
        method: 'POST',
        body: formData,
        signal,
      },
    );
    return handleChatStreamResponse(response, onMessage, onError, onStreamEnd);
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    onError(errorMessage);
  }
};
