import {ToolUsedItemProps, ToolUsedItemWrapper} from '#/components/chat-page/tools-used/ToolUsedItem.tsx';
import {RocketIcon} from '@radix-ui/react-icons';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

const ExecuteCapabilityToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <RocketIcon className='inline-block size-4 shrink-0 text-accent' />

        <span>{t('conversation.tool-used.tools.execute-capability')}</span>
      </div>
    </ToolUsedItemWrapper>
  );
};

export default ExecuteCapabilityToolUsedItem;
