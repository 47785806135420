import PythonCodeMarkdown from '#/components/chat-page/tools-used/PythonCodeMarkdown.tsx';
import {ToolUsedItemProps, ToolUsedItemWrapper} from '#/components/chat-page/tools-used/ToolUsedItem.tsx';
import {useExternalService} from '#/hooks/use-external-service.tsx';
import {castJson} from '#/utils/json-utils.ts';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface ExecuteExternalServicesCodeToolArguments {
  code: string;
  service_name: string;
}

const ExecuteExternalServicesCodeToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<ExecuteExternalServicesCodeToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  const {findExternalServiceByName} = useExternalService();
  const externalService = useMemo(() => {
    if (!toolArguments?.service_name) {
      return undefined;
    }

    return findExternalServiceByName(toolArguments?.service_name);
  }, [findExternalServiceByName, toolArguments?.service_name]);

  return (
    <ToolUsedItemWrapper>
      <div className='border-b border-secondary flex items-center gap-2 pb-2'>
        <img src={externalService?.icon_url} className='rounded-md size-4 shrink-0' alt='' />
        <span>
          {t('conversation.tool-used.tools.execute-external-services-code', {
            serviceName: externalService?.display_name,
          })}
        </span>
      </div>
      <PythonCodeMarkdown code={toolArguments?.code} />
    </ToolUsedItemWrapper>
  );
};

export default ExecuteExternalServicesCodeToolUsedItem;
