import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {useMemo, useState} from 'react';

export const usePromptInputDataToShow = (
  createConversationMutationIsError: boolean,
  message: string,
  files: File[],
  mentionedAssistant: AssistantPublicResponse | null,
) => {
  const [sendMessageIsPending, setSendMessageIsPending] = useState(false);

  const isPending = useMemo(
    () => sendMessageIsPending && !createConversationMutationIsError,
    [createConversationMutationIsError, sendMessageIsPending],
  );

  const messageToShow = useMemo(() => {
    return isPending ? '' : message;
  }, [isPending, message]);

  const filesToShow = useMemo(() => (isPending ? [] : files), [files, isPending]);

  const mentionedAssistantToShow = useMemo(
    () => (isPending ? null : mentionedAssistant),
    [isPending, mentionedAssistant],
  );

  return {messageToShow, filesToShow, mentionedAssistantToShow, sendMessageIsPending, setSendMessageIsPending};
};
