import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {fetchWithTokenRefresh} from '#/utils/request-utils.ts';
import {handleChatStreamResponse, StreamChatCompletionOnMessageEvent} from '#/utils/streaming-utils.ts';

export type StreamTaskDebuggerChatCompletionRequest = {
  messages: ConversationMessage[];
  model: string;
};

export const streamTaskDebuggerChatCompletion = async (
  taskId: string,
  request: StreamTaskDebuggerChatCompletionRequest,
  onMessage: StreamChatCompletionOnMessageEvent,
  onError: (error: string) => void,
  onStreamEnd: () => void,
  signal: AbortSignal,
) => {
  const body = JSON.stringify(request);

  try {
    const response = await fetchWithTokenRefresh(`${ScoutAPI.defaults.baseURL}/tasks/${taskId}/debug/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: body,
      signal,
    });
    return handleChatStreamResponse(response, onMessage, onError, onStreamEnd);
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    onError(errorMessage);
  }
};
